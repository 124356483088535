@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.layout {
    &-header {
        margin: 0 3.3% 3%;
        border-bottom: 0.125em solid colors.$el-gray-400;
        @include global.generic-font-style(900, 1.625rem, 2rem);
        justify-content: space-between;
        align-items: center;
        color: colors.$el-black;


        &-box {
            font: normal normal bold 1.125rem/1.25rem var(--font-family);
            margin: 0.9rem 0;
            width: 100%;
        }
    }

    &-shuffle-box {
        margin: 0.9rem 0;
        cursor: pointer;
    }
}

.collage-panel-scroll {
    height: 92%;
    scrollbar-gutter: stable;
}

@media (max-width: screens.$nest-hub-max) {
    .collage-panel-scroll {
        height: 87%;
        scrollbar-gutter: stable;
    }
}