@use "./../../../../styles/colors.module" as colors;

.help-menu-icon {
    border: none;
    stroke-width: 0rem;
}

.help-menu-dropdown {
    height: 3rem;
    width: 3rem;
}

.menu-item-text {
    color: var(--spectrum-alias-label-text-color, colors.$el-gray-700)
}