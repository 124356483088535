@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.el-faq {
    &__container {
        .spectrum-Accordion-item {
            border: none;
        }

        .spectrum-Accordion-itemHeading {
            display: flex;
            align-items: center;

            button {
                text-transform: none;
                color: colors.$el-light-gray;
                @include global.generic-font-style(600, 1rem, 1.5em);
            }

            .spectrum-Icon {
                margin-top: 0.25rem;
            }
        }
    }

    &__header {
        color: colors.$el-light-gray;
        padding-left: 9.3%;
        align-items: center;
        background: #E1ECF7 0% 0% no-repeat padding-box; //TODO: SPECTRUM_COLOR_CHECK
        height: 3.75rem;
        @include global.generic-font-style(bold, 1.88rem, 2.3rem);
    }

    &__answer {
        color: colors.$el-light-gray;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    &__accordian-container {
        padding-left: 9.3%;
        padding-right: 9.3%;
    }

    &__show-more-container {
        margin-left: 11%;
        margin-top: -1rem;
    }

    &__show-more-button {
        @include global.generic-font-style(normal, 1rem, 1rem);
        background: none;
        border: none;
        color: #0073e6; //TODO: SPECTRUM_COLOR_CHECK
        cursor: pointer;
        text-decoration: underline;
    }

    &__show-more-button:hover {
        color: #005bb5;
    }
}

@media screen and (max-device-width: 1180px) {
    .el-faq {
        &__show-more-container {
            margin-left: 11.5%;
        }

        &__show-more-button {
            font-size: 0.875rem;
        }
    }
}