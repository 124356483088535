@use "./../../../../../global.module" as global;
@use "./../../../../../styles/colors.module" as colors;

.email-dialog-section {
    width: 100%;
}

.email-text-Area {
    width: 100%;
    resize: none;
}

.email-text-field {
    &__label {
        @include global.generic-font-style(normal, 1.125rem, null);

        color: colors.$el-dark-gray; 
        margin-bottom: 0.25rem;
    }

    &__description {
        @include global.generic-font-style(normal, 1.125rem, null, $style: italic);

        /* TODO: SPECTRUM_COLOR_CHECK */
        color: #50504f;
        margin-top: 0.25rem;
    }

    &__characters-limit {
        @include global.generic-font-style(normal, 0.875rem, null, $style: italic);

        color: colors.$el-red-700;
        margin-top: 0.25rem;
    }
}

#email-text-field__msg {
    resize: none;
}
