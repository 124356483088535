@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.photo-text-edit-panel {
    &-box {
        height: 100%;
    }

    &-header {
        margin: 0 3.3% 3%;
        border-bottom: 0.125em solid colors.$el-gray-400;
        @include global.generic-font-style(900, 1.625rem, 2rem);
        justify-content: space-between;
        align-items: center;
        color: colors.$el-black;
    }

    &-body {
        display: flex;
        flex-direction: column;
        margin: 3%;
        gap: 1.5rem;
    }

    &-revert-box {
        margin: 0.9rem 0;
        cursor: pointer;
    }

    &-font-picker {
        flex-direction: column;
        gap: 0.5rem;
        width: 90%;

        &-text {
            @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
            align-content: center;
        }

        &-spread {
            @include global.span-parent(5rem, 3.5rem);

            &-text {
                @include global.generic-font-style(medium, 1rem, 2.5rem);
                text-align: center;
            }
        }
    }

    &-alignment {
        display: flex;
        @include global.span-parent(2.5rem, 2.5rem);
        @include global.flex-center;
        background-color: rgb(253, 253, 253);
        border: 0.0625rem solid rgb(177, 177, 177);
        border-radius: 0.3125rem;
        cursor: pointer;
        box-sizing: border-box;

        &:hover,
        &-selected {
            display: flex;
            @include global.span-parent(2.5rem, 2.5rem);
            @include global.flex-center;
            background-color: rgb(232, 232, 232);
            border: 0.0625rem solid rgb(144, 144, 144);
            border-radius: 0.3125rem;
            cursor: pointer;
            box-sizing: border-box;
        }
    }

    &-scroll {
        @include global.span-parent(100%, 92%);
        scrollbar-gutter: stable;
    }

    @media (max-width: screens.$nest-hub-max) {
        &-scroll {
            @include global.span-parent(100%, 87%);
            scrollbar-gutter: stable;
        }

        &-font-picker-text {
            @include global.generic-font-style(normal, 1.625rem, 1.75rem);
            align-content: center;
        }

        &-font-picker-spread-text {
            @include global.generic-font-style(medium, 1.3125rem, 2.5rem);
            text-align: center;
        }
    }
}