.intro-text{
    &-box ._spectrum-FieldLabel_36b9b {
        font-size: 0.9rem;
    }

    &-desc {
        font-style: italic;
        font-size: 0.7rem;
    }
}
