@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.drop-zone {
    &__container {
        @include global.span-parent(100%, 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 3px dashed colors.$el-gray-400;
        border-radius: 2rem;
    }
    
    &__illustration-message {
        margin-top: -2rem;
        justify-content: start;
    }

    &__heading {
        @include global.generic-font-style(bold,2.25rem,2.8125rem);
        color: colors.$el-light-black-font;
        margin-bottom: 2rem;
    }

    &__content {
        @include global.generic-font-style(normal,1.25rem,1.5625rem);
        color: colors.$el-gray-600;
        margin-bottom: 1.5rem;
    }
}