@use "./../../../../global.module" as global;

.el-custom-footer {
    width: 100vw;
    max-width: 100%;
    min-height: var(--spectrum-global-dimension-size-400);
    display: flex;
    flex-direction: row;
    border: var(--spectrum-global-dimension-size-10) solid var(--spectrum-gray-300);
    background: var(--spectrum-gray-50) 0% 0% no-repeat padding-box;
    align-items: center;
    color: var(--spectrum-gray-600);

    &__link {
        margin-left: var(--spectrum-global-dimension-size-300);
        color: var(--spectrum-gray-600);
        text-decoration: none;
    }

    &__legal {
        margin-left: auto;
        margin-right: var(--spectrum-global-dimension-size-400);
        color: var(--spectrum-gray-600);
    }

    &__lang {
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-left: var(--spectrum-global-dimension-size-300);
        margin-right: var(--spectrum-global-dimension-size-300);
        color: var(--spectrum-gray-600);
        gap: .5rem;
    }
}

@mixin footer-text-mobile {
    margin-top: var(--spectrum-global-dimension-size-50);
    margin-bottom: var(--spectrum-global-dimension-size-50);
}

@media (max-width: 560px) {
    .el-custom-footer {
        justify-content: center;
        flex-wrap: wrap;

        &__link {
            @include footer-text-mobile;
        }

        &__lang {
            @include footer-text-mobile;
        }

        &__legal {
            margin-left: 0px;
            @include footer-text-mobile;
        }
    }
}