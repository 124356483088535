@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;

#creation-preview-container {
    @include global.span-parent(100vw, 100%);
    @include global.fade-in(0.3s);
}

#creation-preview-header-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: 0.0625em solid #cecece;
    /* TODO : SPECTRUM_COLOR_CHECK */
}

#creation-preview-media-container {
    @include global.span-parent(100%, 100%);
    display: flex;
    justify-content: center;
}

.creation-preview-container {
    &-image {
        @include global.span-parent(100%, 100%);
        object-fit:contain;
        align-self: center;
        display: flex;
    }

    &-video {
        @include global.span-parent(100%, 100%);
        object-fit:contain;
        /* TODO : SPECTRUM_COLOR_CHECK */
        border-radius: 0.625rem;
        align-self: center;
    }
}

.creation-preview-main-content-box {
    @include global.creation-content-box;
    display: flex;
    background-color: var(--spectrum-global-color-gray-300);
    @include global.span-parent($width: 100%);
}

.failed-creation {
    &__box {
        width: 100vw;
        height: 100%;
    }

    &__primary_text {
        @include global.generic-font-style(normal, 1.5rem, 1.875rem);
        color: colors.$el-gray-700;
    }

    &__secondary_text {
        @include global.generic-font-style(normal, 1.2rem, 1.5rem);
        color: colors.$el-gray-700;
    }

    &__cta-text {
        @include global.generic-font-style(bold, 1.4rem, 2rem);
        padding: 0 0.8rem;
    }
}


#creation-preview-loading-circle-box {
    @include global.flex() {
        @include global.flex-center;
    }

    @include global.span-parent($height: 100%);
    top: 0;
    left: 0;
    position: absolute;
    background-color: colors.$translucent-white-high;
    z-index: zIndex.$level-2;
}

.creation-progress-container {
    width: 100vw;
    height: 100%;
}