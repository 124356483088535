@use "./../../../../styles/screens.module" as screens;

.el-combo-box {
    width: 6.6rem;
    height: fit-content;
}

@media (max-width: 1370px) {
    .el-combo-box {
        width: 8rem;
    }
}

@media (max-width: screens.$ipad-pro-portrait) {
    .el-combo-box {
        width: 10rem;
    }
}
