@use "./../../../../global.module" as global;

.creations__feature-cta-box {
    @include global.span-parent($height: auto);
    margin: 8% 8% auto;
}

.creations-feature-banner-box__cta {
    align-self: flex-start;
    gap: 3rem;
}

.creations-feature-banner-box__cta-text {
    @include global.generic-font-style(bold, 1.625rem, 2.5rem);
    padding: 0.2rem;
}