.el-option {
    text-align: center;
    background-color: var(--spectrum-alias-background-color-transparent);
    border: none;
    font-family: var(--font-family);

    /* To apply box shadow on hover */

    &:hover {
        border-radius: 0.75rem;
        box-shadow: 0 0 0.3125rem rgb(0 0 0 / 20%);
    }

    /* To remove blue border on selection */

    &:focus {
        outline: 0;
    }
}



