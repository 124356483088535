@use "./../../../../styles/screens.module" as screens;
@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.panel-header-box-with-button {
    padding: 0.9rem 0;
    margin: 0 3.3% 3%;
    border-bottom: 0.125em solid colors.$el-gray-400;
    @include global.generic-font-style(900, 1.625rem, 2rem);
    justify-content: space-between;
    color: colors.$el-black;
}

.slideshow-panel-scroll {
    height: 82%;
    scrollbar-gutter: stable;
}

@media (max-width: screens.$nest-hub-max) {
    .slideshow-panel-scroll {
        height: 77%;
        scrollbar-gutter: stable;
    }
}