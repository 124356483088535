@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

#toolbar {
    @include global.span-parent(10%, 100%);
}

#media-grid {
    @include global.span-parent($height: 100%);
}

#media-grid-container {
    @include global.span-parent(100%, 100%);
    @include global.flex(column);
    background-color: colors.$el-off-white;
}

#import-progress-container {
    @include global.span-parent(100%);
    pointer-events: all;
}

#action-bar {
    pointer-events: all;
}

.action-bar__import-progress-box {
    margin-bottom: 2.125rem;
    gap: 2.125rem;
    pointer-events: none;
}

@media (max-width: screens.$iphone-xr-landscape) {
    .action-bar__import-progress-box {
        gap: 0;
    }
}
