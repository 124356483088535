.slideshow-settings__text {
    font: normal normal bold 1rem/0.6rem var(--font-family);
}

.slideshow-settings__trigger {
    background-color: #E6E6E6;
    /* TODO : SPECTRUM_COLOR_CHECK */
    border-radius: 1rem;
    padding: 0.2rem 0.6rem;
}

.slideshow-settings__action-trigger {
    background-color: transparent;
    border: none;
    color: unset;
}
.slideshow-settings__info-box {
    &:hover {
        background: #D5D5D5 0% 0% no-repeat padding-box;
        border-radius: 4px;
    }
}