@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;

#modal-wrapper-app-container {
    @include global.span-parent(100%, 100%);
}

@media (max-width: screens.$iphone-xr-landscape) {

    #modal-wrapper-app-container {
        flex-direction: column;
        height: 100%;
    }
}
