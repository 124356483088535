@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.qr-banner {
    &-box {
        width: fit-content;
        flex-direction: row;
        color: colors.$el-black;
        background-color: colors.$el-white;
        border-radius: 1rem;

        &__heading {
            @include global.generic-font-style(900, 1.5rem, 1.75rem);
        }

        &__description {
            @include global.generic-font-style(normal, 1.1rem, 1.5rem);
        }

        &-img-content {
            width: 50%;
        }
    }

    &-bottom-box {
        @include global.flex-center;
        width: 50%;
        padding: 2% 0;
    }

    &-img {
        width: 80%;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        object-fit: contain;
        padding: 10%;
    }

    &-download-button {
        width: fit-content;
    }

    &-play-badge {
        &-content {
            flex-direction: column;
            justify-content: space-between;
            width: 13rem;
        }

        &-img {
            @include global.span-parent(100%, 100%);
        }
    }

    &-divider {
        width: 0.1rem;
        background-color: purple;
    }

    &-divider-main {
        width: 0.1rem;
        background-color: lightgray;
        height: 85%;
        align-self: center;
    }

    &-qrcode {
        width: 9rem;
    }
}

@media (max-width: screens.$ipad-pro-portrait) {
    .qr-banner-box {
        flex-direction: column;
        border-radius: 1.5rem;
    }

    .qr-banner-box-img-content {
        width: 100%;
    }

    .qr-banner-bottom-box {
        @include global.flex-center;
        width: 100%;
        padding: 2% 0;
    }

    .qr-banner-content {
        justify-content: center;
    }

    .qr-banner-img {
        padding: 10% 10% 0% 10%;
    }
}

@media (max-width: screens.$iphone-SE-landscape) {
    .qr-banner-box {
        border-radius: 1.5rem;
    }

    .qr-banner-play-badge-content {
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
    }

    .qr-banner-divider {
        display: none;
    }

    .qr-banner-qrcode {
        display: none;
    }

    .qr-banner-img {
        padding: 0% 10% 10% 10%;
    }
}