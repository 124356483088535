@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;
@use "./../../../../styles/zlevel.module" as zIndex;

@mixin desktop_mobile{
    &_desktop {
        display: unset;
    }

    &_mobile {
        display: none;
    }
}

@mixin mobile_desktop{
    &_desktop {
        display: none;
    }

    &_mobile {
        display: unset;
    }
}

.el-progress-bar{

    &-footer {
        @include global.span-parent(100%, 6.25rem);
        /* TODO for fixing z-index */
        z-index: zIndex.$level-max;
        background: colors.$el-white;
        /* SPECTRUM_COLOR_CHECK */
        box-shadow: 0px -2px 6px #0000001A;
        justify-content: space-around;
        gap: 1rem;
        padding: 0 2%;
        position: absolute;
        bottom: 0;

        &__stop_trigger{
            @include desktop_mobile;
        }

        &__retry{
            @include desktop_mobile;
        }
    }

    &__message {
        width: max-content;

        &-box {
            @include global.generic-font-style(bold, 1.25rem, 1.875rem);
            /* SPECTRUM_COLOR_CHECK */
            color: #292929;
            margin: auto;
            @include global.flex($justify: center);
        }
    }

    &__button-grp {
        margin: auto 4rem auto auto;
    }
  }

.progress-bar {
    width: 57%;
    margin: auto 1rem auto 0rem;
    @include global.flex($align: center);
    gap: 1rem;
    flex-grow: 1;
}

.no-opacity {
    opacity: 1;
}

.progressContainer {
    @include global.span-parent(100%, 1.2rem);
    /* SPECTRUM_COLOR_CHECK */
    background: #E1E1E1 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 2px 4px #00000029;
    border-radius: 60px;
    opacity: 1;
}

.progressIndicator {
    height: 1.2rem;
    border-radius: 60px 60px 60px 60px;
    opacity: 1;
    transition: width 1s linear;
}

.progressIndicatorColor{

    &__inProgress {
        /* SPECTRUM_COLOR_CHECK */
        background: #268E6C 0% 0% no-repeat padding-box;
    }

    &__error {
        /* SPECTRUM_COLOR_CHECK */
        background: #C9252D 0% 0% no-repeat padding-box;
    }
}

.progressButtonText {
    white-space: nowrap;
}

/* iphone XR landscape mode */
@media (max-width: screens.$iphone-xr-landscape) {

    .el-progress-bar{

        &-footer {
            @include global.flex(column, center, center);
            height: 8rem;
            padding: 0;

            &__stop_trigger{
                @include mobile_desktop;
            }

            &__retry{
                @include mobile_desktop;
            }
        }

        &__message-box {
            @include global.generic-font-style(bold, 1.8rem, 1.875rem);
            width: fit-content;
            margin: 0;
            margin-top: 1rem;
        }

        &__button-grp {
            margin: 0;
        }
    }

    .progress-bar {
        @include global.flex($align: center);
        width: 92%;
        gap: 2rem;
        margin: auto;
    }
}
