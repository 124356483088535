@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.create-on-demand-text-box {
    text-align: left;
    @include global.generic-font-style(900,2rem,2.81rem);
    color: #292929; /* TODO - SPECTRUM_COLOR_CHECK */
    border-bottom: 1px solid #cecece;
    height: 4.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.create-on-demand {
    &__view-all-button {
        @include global.generic-font-style(normal, 1rem, 1rem);
        background: none;
        border: none;
        color: colors.$el-indigo-600;
        cursor: pointer;
        padding: 0;
    }

    &__dialog-content {
        scrollbar-gutter: stable;
    }
}
