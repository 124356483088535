@use "./../../../global.module" as global;
@use "./../../../styles/colors.module" as colors;
@use "./../../../styles/screens.module" as screens;

.noaccess-feature-banner-box__hello {
    @include global.generic-font-style(bold, 1.625rem, 1.75rem);
    color: colors.$el-black;
}

.noaccess-feature-cta-box {
    @include global.span-parent($height: auto);
    @include global.flex($direction: column);
    margin: 3% 12%;
}

.noaccess-user-do-not-have-access {
    @include global.generic-font-style(bold, 1.312rem, 1.75rem);
    @include global.span-parent($width: 70%);
    color: colors.$el-red;
}

@media (max-width: screens.$nest-hub-max) {
    .noaccess-feature-cta-box {
        @include global.span-parent($height: auto);
    }

    .noaccess-user-do-not-have-access {
        @include global.span-parent($width: 90%);
        @include global.generic-font-style(bold, 1.34rem, 2.25rem);
    }
}

@media (max-width: screens.$ipad-pro-portrait) {
    .noaccess-feature-cta-box {
        @include global.span-parent($height: auto);
    }

    .noaccess-feature-banner-box__hello {
        @include global.generic-font-style(bold, 2.625rem, 1.75rem);
    }

    .noaccess-user-do-not-have-access {
        @include global.generic-font-style(normal, 2.25rem, 2.65rem);
        @include global.span-parent($width: 100%);
    }
}

@media (max-width: screens.$iphone-SE-landscape) {
    .noaccess-feature-banner-box__hello {
        @include global.generic-font-style(bold, 1.625rem, 1.75rem);
    }

    .noaccess-user-do-not-have-access {
        @include global.generic-font-style(normal, 1.312rem, 1.75rem);
        @include global.span-parent($width: 100%);
    }
}