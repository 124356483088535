@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.no-media-banner {
    @include global.span-parent(100%,100%);
    @include global.flex(column,center,center);
    gap: 5rem;

    &-box {
        @include global.span-parent(null,100%);
    }

    &-heading {
        font-size: 2rem;
        font-family: inherit;
        color: colors.$el-gray-900;
        opacity: 1;
        font-weight: 600;
    }
}

/* in landscape mode and mobile devices (iPhone XR landscape size) */
@media (orientation:landscape) and (max-width: screens.$iphone-xr-landscape){
    .no-media-banner-box {
        padding-top: 4.5em;
        padding-bottom: 9em;
    }
}
