@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;

.el-media-grid{
    &-container {
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        @include global.span-parent(100%,100%);
    }

    &-scroll-container__loading-circle {
        @include global.span-parent(100%,100%);
        /* TODO: SPECTRUM_COLOR_CHECK */
        background-color: colors.$app-loading-circle;
        margin: auto;
        position: absolute;
        z-index: zIndex.$level-1;
        @include global.flex(column,center,center);
        gap: 1rem;
    }
}

.img-thumb {
    position: inherit;
    max-height: 90%;
    max-width: 100%;
}

#grid-toolbar {
    @include global.span-parent(100%);
    height: var(--spectrum-global-dimension-size-1000, var(--spectrum-alias-size-1000));
}

.grid{
    &-section-header {
        position: absolute;
        @include global.generic-font-style(bold,1.5rem,1.875rem);
        /* SPECTRUM_COLOR_CHECK */
        color: #292929;
    }

    &-anchor {
        position: absolute;
        @include global.span-parent(1px,1px);
    }

    &-media-thumb-container {
        position: absolute;
        cursor: pointer;

        .el_gif_N__text{
            @include global.generic-font-style(700,1.4375rem,normal);
        }
    }
}
