@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;
@use "./../../../../styles/zlevel.module" as zIndex;

.dialog{
    &-box {
        @include global.flex(null,center,center);
        @include global.fade-in(0.3s);
        position: fixed;
        background: rgba(0, 0, 0, 0.3);
        z-index: zIndex.$level-5;

        &:focus {
            outline: none;
        }

        &__animate {
            transition: width ease-in 0.3s, height ease-in 0.3s;
        }

        &__children {
            background: colors.$el-white;
            /* TODO: SPECTRUM_COLOR_CHECK */
            position: relative;
            @include global.span-parent(96%,90%);
            box-shadow: var(--spectrum-global-dimension-size-0) 0.18rem 1.25rem #0000003D;
            /* TODO: SPECTRUM_COLOR_CHECK */
            opacity: 1;
            border-radius: 0.18rem;
        }
    }

    &__cta-text {
        @include global.generic-font-style(bold,1.625rem,2.5rem);
        width: max-content;
        padding: 0 0.2rem;
    }

    &__header{
        &-box {
            flex-direction: column;
            margin-top: 0.8rem;
        }

        &-content {
            text-align: left;
            @include global.generic-font-style(normal,1.375rem,1.688rem,var(--font-family),italic);
            letter-spacing: var(--spectrum-global-dimension-size-0);
            color: #292929;
            /*TODO - SPECTRUM_COLOR_CHECK*/
            margin-top: 0.75rem;
        }
    }

    &__content-container {
        @include global.grid();
        overflow-y: auto;
    }

    &__title {
        text-align: left;
        @include global.generic-font-style(900,2.25rem,2.813rem);
        letter-spacing: var(--spectrum-global-dimension-size-0);
        color: #292929;
        /*TODO - SPECTRUM_COLOR_CHECK*/
    }
}

/* iphone XR landscape mode */
@media (max-width: screens.$iphone-xr-landscape) {
    .dialog-box__children {
        position: absolute;
        bottom: 0px;
    }
}

/* in landscape mode and mobile devices (iPhone XR landscape size) */
@media (orientation: landscape) and (max-width: screens.$iphone-xr-landscape){
    .dialog__header-box {
        flex-direction: row;
        gap: 1rem;
    }
}
