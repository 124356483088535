#create-on-demand-container {
    
}

#recent-creations-container {
    margin-top: 2.5em;
    padding-bottom: 10%;
}

#creation-home-view-container {
    padding-left: 5%;
    padding-right: 5%;
}
