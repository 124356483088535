@use "./../../../global.module" as global;
@use "./../../../styles/screens.module" as screens;

#sample-media-wrapper-app-container {
    @include global.span-parent(100%, 100%);
}

@media (max-width: screens.$iphone-xr-landscape) {

    #sample-media-wrapper-app-container {
        flex-direction: column;
        height: 100%;
    }
}
