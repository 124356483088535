@use "./../../../../styles/zlevel.module" as zIndex;
.arrow-content-box {
    z-index: zIndex.$level-1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
        height: 3rem;
    margin-left: 0.4em;
    margin-right: 0.4em;
    border-radius: 50%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.1875em 0.375em #00000029;
}

.arrow-box {
    border-right: 0.1875rem solid #4646C6;
    border-bottom: 0.1875rem solid #4646C6;
    height: 0.625rem;
    width: 0.625rem;
}

.right {
    position: absolute;
    transform: rotate(-45deg) translate(-0.125em, 0);
    -webkit-transform: rotate(-45deg) translate(-0.125em, 0);
}

.left {
    position: absolute;
    transform: rotate(135deg) translate(-0.125em, 0);
    -webkit-transform: rotate(135deg) translate(-0.125em, 0);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}