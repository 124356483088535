
.open-in-desktop-dialog {
    &__text {
    margin: 0%;
    }

    &__ok-button {
        margin-top: 3em;
        margin-left: auto;
    }
}