@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;

#slideshow-container {
    @include global.span-parent(100vw, 100%);
    @include global.fade-in(0.3s);
}

#slideshow-header-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: 0.0625em solid #cecece;
    /* TODO : SPECTRUM_COLOR_CHECK */
}

#slideshow-video-container {
    @include global.span-parent(100%, 100%);
    min-width: 21.875rem;
    display: flex;
    position: relative;
    top: 10%;
    left: 7%;
}

.slideshow-feedback-icon-container {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

.slideshow-video {
    @include global.span-parent(80%, 80%);
    border: 0.08rem solid #2C2C2C;
    /* TODO : SPECTRUM_COLOR_CHECK */
    border-radius: 0.625rem;
}

.slideshow-main-content-box {
    @include global.creation-content-box;
    position: relative;
    background-color: var(--spectrum-global-color-gray-300);
}

.failed-slideshow__box {
    width: 100vw;
    height: 100%;
}

.failed-slideshow__primary_text {
    @include global.generic-font-style(normal, 1.5rem, 1.875rem);
    color: colors.$el-gray-700;
}

.failed-slideshow__secondary_text {
    @include global.generic-font-style(normal, 1.2rem, 1.5rem);
    color: colors.$el-gray-700;
}

.failed-slideshow__cta-text {
    @include global.generic-font-style(bold, 1.4rem, 2rem);
    padding: 0 0.8rem;
}

#slideshow-loading-circle-box {
    @include global.flex() {
        @include global.flex-center;
    }

    @include global.span-parent($height: 100%);
    top: 0;
    left: 0;
    position: absolute;
    background-color: colors.$translucent-white-high;
    z-index: zIndex.$level-2;
}

.slideshow-progress-container {
    width: 100vw;
    height: 100%;
}