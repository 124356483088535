.grid-media-tile {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: max(0.3125rem, 7px);
    background-color: var(--spectrum-global-color-gray-50);
}

.grid-media-tile__selected {
    border: solid var(--spectrum-global-color-purple-700);
    border-width: max(0.0625rem, 1px);
}

.grid-media-tile__hover{
    border: solid var(--spectrum-global-color-purple-700);
    border-width: max(0.0625rem, 1px);
}

.media-thumb-checkbox {
    margin: 0 !important;
    padding: 0 !important; /* important since spectrum checkbox overrides this */
}

.checkBox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: max(10px, 0.625rem);
    left: max(10px, 0.625rem);
    height: max(40px, 2.5rem);
    width: max(40px, 2.5rem);
    background-color: var(--spectrum-global-color-gray-200);
    border-radius: max(0.3125rem, 5px);
}

.thumb-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

._spectrum-Checkbox-input_0ffa4 {
    cursor: pointer;
}

.progress_loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}


.progress_loader ._spectrum-CircleLoader-fill_40b43{
    border-color: var(--spectrum-global-color-gray-300);
}

.progress_loader ._spectrum-CircleLoader-track_40b43{
    border-color: var(--spectrum-global-color-gray-200);
}

.loading-background {
    background-color: var(--spectrum-global-color-gray-50);
}

.thumb_icon {
    position: absolute;
}