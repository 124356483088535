@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.color-filling-container {
    @include global.span-parent(100%, 100%);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-filling {
    @include global.span-parent(100%, 100%);
    cursor: pointer;
}

.color-filling-icon {
    height: 1.2rem;
    position: absolute;
    cursor: pointer;
}