@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.badge {
    &-container {
        position: relative;
        border: 1px;
        .primary {
            background-color: colors.$el-red-700;
            color: colors.$el-white;
            // adding this same from ELCreateOnDemandThumb.scss
            // right side border needed for shadowing
            border-right: 1px solid #E0E0E0;
        }

        .secondary {
            background-color: colors.$el-seafoam;
            color: colors.$el-white;
        }

        .cta {
            background-color: colors.$el-seafoam;
            color: colors.$el-black;
        }
    }

    &-icon {
        @include global.generic-font-style(normal, 0.9rem, 1rem);
        display: flex;
        justify-content: center;
        width: fit-content;
        min-width: 0.7rem;
        padding: 0.1rem 0.3rem 0.1rem 0.3rem;
        position: absolute;
        right: 0rem;
        top: 1rem;
    }
}