@use "./../../../../global.module" as global;
.carousel-contentaier {
    position: relative;
}

.carousel-content-box {
    max-width: 100vw;
    @include global.flex(row,center,center);

    &__scroller {
        @include global.flex(null);
        scrollbar-width: none;
        column-gap: 1.25rem;
        margin: 0.625rem 0rem;
        
            &::-webkit-scrollbar {
                display: none;
            }
    }
}
