@use "./../../../global.module" as global;

#edit-workflow-container {
    @include global.span-parent(100vw, 100%);
    @include global.fade-in(0.3s);
}    

#edit-workflow {
    &__header-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        /*TODO: SPECTRUM_COLOR_CHECK*/
        border-bottom: 0.0625em solid #cecece;
    }  

    &__doc-container {
        @include global.flex();
        position: relative;
        overflow: hidden;
        flex-direction: row;
        flex-grow: 1;
    }
}
    
.edit-workflow__main-content-box {
    @include global.flex();
    @include global.creation-content-box;
}