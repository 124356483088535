:root {
  /* TODO: need to have spectrum color once XD confirms */
  --light-theme-indigo-color: #7B1DC6;
  --font-family: adobe-clean;
}

:lang(ja-JP) {
  --font-family: adobe-clean-han-japanese;
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
  user-select: none;
  -webkit-touch-callout: none;
  /* disable 3D touch, only supported on Safari */
}

html [type="button"] {
  -webkit-appearance: none;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#g-recaptcha {
  display: none;
}

.w-100 {
  width: 100%;
}

* {
  touch-action: manipulation;
}

.hidden {
  visibility: hidden;
}

.no-display {
  display: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.cta-span {
  font-size: 1rem;
}

._spectrum-CircleLoader-fill_40b43 {
  border-color: var(--spectrum-global-color-indigo-700);
}


.el-spectrum-action-button {
  -webkit-appearance: none;
  border: none;
  background-color: #F7F7F7;
}

.el-spectrum-action-button:hover {
  background-color: #EAEAEA;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* 4K UHD desktop screen */
@media (max-width: 3840px) {
  html {
    font-size: 32px;
  }
}

/* 4K UHD desktop screen - 256 */
@media (max-width: 3584px) {
  html {
    font-size: 30px;
  }
}

/* WQHD desktop screen */
@media (max-width: 3440px) {
  html {
    font-size: 28px;
  }
}

/* 4K UHD desktop screen - (256 * 2) */
@media (max-width: 3328px) {
  html {
    font-size: 27px;
  }
}

/* 4K UHD desktop screen - (256 * 3) */
@media (max-width: 3072px) {
  html {
    font-size: 25px;
  }
}

/* 4K UHD desktop screen - (256 * 4) */
@media (max-width: 2816px) {
  html {
    font-size: 23px;
  }
}

/* QHD desktop screen */
@media (max-width: 2560px) {
  html {
    font-size: 21px;
  }
}

/* QHD desktop screen - 256 */
@media (max-width: 2304px) {
  html {
    font-size: 19px;
  }
}

/* QWXGA desktop screen */
@media (max-width: 2048px) {
  html {
    font-size: 17px;
  }
}

/* spec and FHD and WUXGA desktop screen */
@media (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}

/* WSXGA+ desktop screen */
@media (max-width: 1680px) {
  html {
    font-size: 15px;
  }
}

/* WXGA+ desktop screen */
@media (max-width: 1440) {
  html {
    font-size: 14px;
  }
}

/* ipad pro landscape mode */
@media (max-width: 1366px) {
  html {
    font-size: 13px;
  }
}

/* ipad pro portrait mode */
@media (max-width: 1024px) {
  html {
    font-size: 12px;
  }
}

/* iphone XR landscape mode */
@media (max-width: 896px) {
  html {
    font-size: 11px;
  }
}

/* MOTO G4 landscape mode */
@media (max-width: 640px) {
  html {
    font-size: 10px;
  }
}

/* iphone 12 Pro Portrait mode */
@media (max-width: 390px) {
  html {
    font-size: 9px;
  }
}