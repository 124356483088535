@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;
.popup {
    position: fixed;
    bottom: 7.75rem;
    right: 5.75rem;
    width: 19.375rem;
    height: 23.75rem;
    background: colors.$el-gray-900;
    border-radius: 0.625rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(1.875rem);
    z-index: zIndex.$level-max;
    overflow: hidden;

    &__get-started-button {
        background-color: colors.$el-violet;
        border-color: colors.$el-violet;
        padding: 0.625rem 1.25rem;
        border-radius: 1.25rem;
        cursor: pointer;
        font-size: 1.1rem;
        margin-top: 1rem;
        height: 10%;
    }

    &__get-started-button:hover {
        background-color: colors.$el-purple;
        border-color: colors.$el-purple;
    }

    &__creation-text {
        @include global.generic-font-style(lighter, 1.25rem, 2rem, var(--font-family));
        color: colors.$el-black;
        margin-top: 1.875rem;
    }

    &__close-button {
        background: none;
        border: none;
        color: colors.$el-black;
        font-size: 1.25rem;
        cursor: pointer;
        position: absolute;
        top: 0.625rem;
        right: 0.625rem;
        z-index: zIndex.$level-4;
    }

    &__content {
        text-align: center;
        z-index: zIndex.$level-3;
    }

    &__video {
        @include global.span-parent(100%, auto);
        border-radius: 0.3125rem;
    }
}