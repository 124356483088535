@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;

@mixin info-panel-field {
    @include global.generic-font-style(normal, 1.12rem, 1.75rem);
    margin: 1rem;
    width: 100%;
    color: #4B4B4B;/*TODO: SPECTRUM_COLOR_CHECK */
}

.info-panel {
    &-heading {
        background-color: #F5F5F5;/*TODO: SPECTRUM_COLOR_CHECK */
        @include global.generic-font-style(bold, 1.4rem, 1.7rem);
        justify-content: flex-end;
        height: 8%;
        width: 100%;
        align-items: center;
        column-gap: 35%;
    }

    &-field {
        @include info-panel-field();
        gap: 10%;
    }

    &-field-container {
        @include info-panel-field();
    }

    &-field-heading {
        @include global.generic-font-style(bold, 1.25rem, 1.75rem);
    }

    &-asset-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
    }

    &-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}

@media (max-width: screens.$iphone-xr-landscape) {

    .info-panel-heading {
        height: 9%;
        column-gap: 38%;
    }
}




