@use "./../../../../global.module"as global;
@use "./../../../../styles/dialog.module"as dialog;
@use "./../../../../styles/screens.module"as screens;

$el-confirmation-dialog-color: #747474;  /* TODO: SPECTRUM_COLOR_CHECK */

.delete-confirmation-dialog {
    @include dialog.container(30vw, fit-content);

    &__h1 {
        @include global.generic-font-style(900, 2rem, 2.25rem);
    }

    &__h2 {
        @include global.generic-font-style(normal, 1.25rem, normal, $style: italic);
        padding: 0.3em;
        color: $el-confirmation-dialog-color;  /* TODO : SPECTRUM_COLOR_CHECK */
    }

    &__text {
        @include global.generic-font-style(normal, 1.1375rem, 1.575rem);
        text-align: left;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

/* iphone X landscape mode */
@media (max-width: screens.$iphone-x-landscape) {
    .confirmation-dialog {
        @include dialog.container(100%, 90vh);
    }
}