@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.tab {
    &-button-primary-container {
        justify-content: center;
        @include global.span-parent(4.4rem,4rem);
        border-top-right-radius: 1em;
        border-bottom-right-radius: 1em;
        margin: 0 !important;

        &.selected, &.selected:hover {
            background-color: colors.$el-indigo-700; /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &:hover {
            background-color: #dedef7; /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &.selected::after, &.selected::before, &:hover::before, &:hover::after {
            content: "";
            @include global.span-parent(20px,20px);
            left: 0;
            position: absolute;
            transform: scale(0.8);
            background-repeat: no-repeat;
            background-size: 20px 20px;
        }

        /**TODO - niskumar: should we make separate svg file for background-image? */
        &.selected::after, &.selected:hover::after {
            background-image: url('data:image/svg+xml;utf8,<svg width="1.25rem" height="1.25rem" xmlns="http://www.w3.org/2000/svg"><path fill="%235151d3" d="M0 0 Q20 0 20 20 L20 0Z" transform="scale(-1,-1) translate(-20,-20)"/></svg>');
            top: -17px;
            left: -3px;
        }

        &:hover::after {
            background-image: url('data:image/svg+xml;utf8,<svg width="1.25rem" height="1.25rem" xmlns="http://www.w3.org/2000/svg"><path fill="%23dedef7" d="M0 0 Q20 0 20 20 L20 0Z" transform="scale(-1,-1) translate(-20,-20)"/></svg>');
            top: -17px;
            left: -3px;
        }

        &.selected::before, &.selected:hover::before {
            background-image: url('data:image/svg+xml;utf8,<svg width="1.25rem" height="1.25rem" xmlns="http://www.w3.org/2000/svg"><path fill="%235151d3" d="M0 20 Q20 20 20 0 L20 20Z" transform="scale(-1,-1) translate(-20,-20)" /></svg>');
            bottom: -17px;
            left: -3px;
        }

        &:hover:before {
            background-image: url('data:image/svg+xml;utf8,<svg width="1.25rem" height="1.25rem" xmlns="http://www.w3.org/2000/svg"><path fill="%23dedef7" d="M0 20 Q20 20 20 0 L20 20Z" transform="scale(-1,-1) translate(-20,-20)" /></svg>');
            bottom: -17px;
            left: -3px;
        }
    }

    &-button-secondary-container {
        justify-content: center;
        @include global.span-parent(4.4rem, 4rem);
        border-radius: 0.75em;
        margin: 0.3125rem;

        &.selected, &.selected:hover {
            background-color: colors.$el-gray-300; /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &:hover {
            background-color: colors.$el-gray-300; /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &.selected::after, &.selected::before, &:hover::before, &:hover::after {
            content: "";
            @include global.span-parent(20px, 20px);
            left: 0;
            position: absolute;
            transform: scale(0.8);
            background-repeat: no-repeat;
            background-size: 20px 20px;
        }
    }

    &-icon-container {
        @include global.grid(center);
        @include global.span-parent(2rem,2rem);
    }

    &-text-container {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 0;
        @include global.generic-font-style(800,0.75rem,1rem);
    }

    &-text-container, &-text-container:hover {
        color: colors.$el-black;
    }

    &-text-container.selected, &-text-container.selected:hover {
        color: colors.$el-black;
    }
}

._spectrum-Tabs--vertical_0248f ._spectrum-Tabs-item_0248f {
    @include global.span-parent(4.8rem,4rem);
    margin: 0px;
}
