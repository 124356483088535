.video-overlay {
    &-container {
        height: min(100%, 36rem);
        width: min(100%, 64rem);
    }

    &__iframe {
        width: 100%;
        height: 100%;
        position: absolute; 
        left: 0px;
        top: 0px
    }
}