@use "./../global.module" as global;
@use "./../styles/colors.module" as colors;
@use "./../styles/zlevel.module" as zIndex;

.workspace-container {
    @include global.span-parent($height: calc(100% - global.$navbar-height));
    /*6rem height of navbar*/
}

#full-screen-workflow-container {
    @include global.span-parent($height: 100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: zIndex.$level-2;
    background-color: colors.$el-off-white;
}

.app-view__loading-circle {
    @include global.flex(column) {
        @include global.flex-center;
    }

    @include global.span-parent(100%, 100%);
    background-color: colors.$app-loading-circle;
    margin: auto;
}
