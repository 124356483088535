@use "./../../../../styles/screens.module" as screens;
@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;


.el-share-option-panel {
    @include global.span-parent(20rem);
    justify-content: space-evenly;
    margin: 0.3rem 0.625rem;
    flex-direction: row;

    @media (max-width: screens.$iphone-xr-landscape) {
        width: 100%;
    }

    &__icon {
        @include global.span-parent(2.15rem, 2.15rem);

        @media (max-width: screens.$iphone-xr-landscape) {
            @include global.span-parent(3.15rem, 3.15rem);
        }
    }

    &__qr-icon {
        @include global.span-parent(1.125rem, 1.125rem);

        @media (max-width: screens.$iphone-xr-landscape) {
            @include global.span-parent(2.15rem, 2.15rem);
        }
    }

    &__box {
        width: 10rem;
        padding: 1.28rem 3.88rem;
    }

    &__progress-text {
        @include global.generic-font-style(normal, 1rem, 1.25rem);
        color: #505050;
        /* TODO: SPECTRUM_COLOR_CHECK */
    }

    &-qr-image {
        @include global.span-parent(9.5rem, 9.5rem);
    }

    &__qr-progress-loader {
        @include global.span-parent(9.5rem, 9.5rem);
        @include global.flex(row, center, center);
        background-color: colors.$app-loading-circle;
    }

    &-qr-text-1 {
        @include global.span-parent(17rem);
        @include global.generic-font-style(normal, 1.125rem, 1.325rem);
        display: flex;
        justify-content: center;
        font-size: 0.95rem;
        margin-bottom: 0.75rem;
    }

    &-qr-text-2 {
        @include global.span-parent(19rem);
        @include global.generic-font-style(normal, 1.125rem, 1rem);
        display: flex;
        justify-content: center;
    }

    &__view,
    &__qr-view {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        align-items: center;
    }

    &-option-1 {
        flex-direction: row;
        justify-content: center;
    }

    &-qr-box {
        flex-direction: column;
        align-items: center;
    }

    &-option-2 {
        flex-direction: row;
    }

    &-line {
        @include global.span-parent(100%);
        border-bottom: 0.0625rem solid colors.$el-gray-300;
    }
}