@use "./../../../../styles/colors.module" as colors;

.el-button {

    font-family: var(--font-family);

    &-primary {
        color: colors.$el-indigo-700;
        /* TODO : SPECTRUM_COLOR_CHECK */
        border-color: colors.$el-indigo-700;
        /* TODO : SPECTRUM_COLOR_CHECK */

        &:hover {
            color: colors.$el-indigo-700;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: colors.$el-indigo-700;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: #5C5CE034;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &:focus {
            color: colors.$el-indigo-700;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: colors.$el-indigo-700;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$el-white
                /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &-disabled,
        &-disabled:hover {
            color: colors.$el-gray-400;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: colors.$el-gray-400;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$el-background-transparent;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }
    }


    &-secondary {
        color: colors.$el-gray-900;
        /* TODO : SPECTRUM_COLOR_CHECK */
        background-color: #E6E6E6;
        /* TODO : SPECTRUM_COLOR_CHECK */
        border-color: #E6E6E6;

        &:hover {
            color: colors.$el-gray-900;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: #D5D5D5;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: #D5D5D5;
        }

        &:focus {
            color: colors.$el-gray-900;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: #E6E6E6;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: #E6E6E6;
        }

        &:focus:hover {
            color: colors.$el-gray-900;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: #D5D5D5;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: #D5D5D5;
        }

        &._is-active_7a745 {
            color: colors.$el-gray-900;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: #E6E6E6;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &._is-active_7a745:hover {
            color: colors.$el-gray-900;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: #D5D5D5;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &-disabled,
        &-disabled:hover,
        &-disabled:focus {
            border-color: colors.$el-gray-400;
            color: #909090;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$el-background-transparent;
        }
    }

    &-cta {
        color: colors.$el-white;
        /* TODO : SPECTRUM_COLOR_CHECK */
        border-color: colors.$el-indigo-400;
        /* TODO : SPECTRUM_COLOR_CHECK */
        background-color: colors.$el-indigo-400;
        /* TODO : SPECTRUM_COLOR_CHECK */

        &:hover {
            color: colors.$el-white;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: colors.$el-indigo-500;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$el-indigo-500;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &-disabled,
        &-disabled:hover {
            color: colors.$el-gray-400;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: colors.$el-gray-400;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$el-background-transparent;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }
    }

    &-large {
        height: fit-content;
        border-radius: 3rem;
        min-width: 0rem; //this is to overwrite min-width specified in react-spectrum button 
        /* need larger border radius then spectrum default */
    }

    &-custom-cta {
        height: 3.125rem;
        border-radius: 3rem;
        border-width: max(3px, 0.1875rem);
        font-family: var(--font-family);
        font-size: 1.375rem;
    }

    &-accent {
        color: #525252;
        /* TODO : SPECTRUM_COLOR_CHECK */
        border-color: #525252;
        /* TODO : SPECTRUM_COLOR_CHECK */

        &:hover {
            color: #525252;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: #525252;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$translucent-black-low;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &:focus {
            color: #525252;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: #525252;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$el-white
                /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &-disabled,
        &-disabled:hover {
            color: colors.$el-gray-400;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-color: colors.$el-gray-400;
            /* TODO : SPECTRUM_COLOR_CHECK */
            background-color: colors.$el-background-transparent;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }
    }
}