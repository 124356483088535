@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.try-this-in-desktop {
    &-page {
        @include global.span-parent(100vw, 100%);
        justify-content: center;
        align-items: center;
    }

    &-home {
        @include global.span-parent(40%);
        flex-direction: column;
        gap: 3.125rem;
    }

    &-image {
        @include global.span-parent(7.1875rem, 7.1875rem);
    }

    &-text {
        &-1 {
            @include global.generic-font-style(bold, 2.25rem, 2.8125rem);
            color: colors.$el-light-gray;
            text-align: center;
        }

        &-2 {
            @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
            color: colors.$el-light-gray;
            text-align: center;
        }

        &-3 {
            @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
            text-align: center;
        }

        &-4 {
            @include global.generic-font-style(normal, 1rem, 1.25rem);
            color: colors.$el-gray-800;
            text-align: center;
        }
    }

    &-page-mobile {
        @include global.span-parent(100vw, 100%);
        justify-content: center;
        align-items: center;
    }

    &-home-mobile {
        @include global.span-parent(22.4375rem);
        flex-direction: column;
        gap: 3.125rem;
    }

    &-mobile-text {
        &-1 {
            @include global.generic-font-style(bold, 1.875rem, 2.3125rem);
            color: colors.$el-light-gray;
            text-align: center;
        }

        &-2 {
            @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
            color: colors.$el-light-gray;
            text-align: center;
        }
    }
}