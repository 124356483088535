@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.elive-card {
    &__platform-text {
        @include global.generic-font-style(500, 1rem, 1rem);
    }

    &__cta {
        @include global.generic-font-style(900, 0.88rem, 1.06rem);
    }

    &__footer .el-button-accent {
        min-height: fit-content;
        height: fit-content;
        min-width: fit-content;
        width: fit-content;
    }
}