@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;

.creation-preview-header {
    height: 4.5rem;
    justify-content: flex-start;
    gap: 0.8rem;
    direction: "row";
    align-items: center;

    &__edit-box {
        max-width: 66%;
        @include global.span-parent(fit-content);
        display: contents;
    }

    &__title {
        &-text {
            @include global.generic-font-style(700, 1.4rem, 2.8rem);
            padding-left: 0.1em;
            padding-right: 0.1em;
            /* TODO : SPECTRUM_COLOR_CHECK */
            color: #292929;
            white-space: nowrap;
            overflow: clip;
            max-width: 18rem;
            @include global.span-parent(fit-content);
            text-overflow: ellipsis;
        }

    }

    &__cta-box {
        @include global.span-parent(50%);
        justify-content: flex-end;
        align-items: center;
        direction: row;
        gap: 1rem;
    }

    &__text {
        &-box {
            cursor: default;
            @include global.span-parent(50%);

            ._spectrum-Textfield-input_61339 {
                @include global.generic-font-style(900, 1.6rem, 3rem);
                padding-left: 0.1em;
                padding-right: 0.1em;
                @include global.span-parent(100%, fit-content);
            }
        }

        &-input {
            @include global.span-parent(66%);
            @include global.generic-font-style(900, 1.6rem, 3rem);
            max-width: 18rem;
        }
    }
}

.workflow-header {

    &__cta-text {
        color: colors.$el-light-gray;
        @include global.generic-font-style(bold, 1.125rem, 1.875rem);
        letter-spacing: 0.72px;

    }

    &__cta-text-edit {
        @include global.generic-font-style(bold, 1.125rem, 1.875rem);
        letter-spacing: 0.72px;

        color: colors.$el-white;
    }

    &__preview-tag {
        @include global.generic-font-style(bold, 1.5rem, 1.875rem);
        color: colors.$el-light-black-font;
    }
}

._spectrum_d43d2 {
    background-color: transparent;
}

@media (max-width: screens.$ipad-pro-portrait) {
    .workflow-header__cta-text {
        display: none;
    }

    .workflow-header__cta-text-edit {
        display: none;
    }
}