@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

@mixin text-on-image {
    position: absolute;
    @include global.generic-font-style(bold, 1.0625rem, 2.5rem);
    color: colors.$el-light-gray;
}

.collapsible-panel-header-text {
    text-align: left;
    @include global.generic-font-style(bold, 1.25rem, 1.4375rem);
    color: colors.$el-light-gray;
    width: 100%;
}

.collapsible-panel-container {
    &-initial {
        max-height: 0rem;
        margin-left: 1rem;
        overflow: hidden;
    }

    &-final {
        @include global.fade-in(0.3s);
        display: block;
        margin: 1rem;
        overflow: hidden;
    }
}

.collapsible-panel-box {
    @include global.flex-center;
    background: transparent;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.1825rem;
    position: relative;

    .collapsible-panel-image-text {
        @include text-on-image;
        opacity: 0;
    }

    &:hover .collapsible-panel-image-text {
        @include text-on-image;
        opacity: 1;
    }

    &-selected {
        @include global.flex-center;
        background: transparent;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 0.1825rem solid colors.$el-purple;
        border-radius: 0.625rem;
        box-sizing: border-box;
        padding: 0.1825rem
    }
}

.collapsible-panel-image {
    max-width: 100%;
    max-height: 100%;
    align-items: center;

    &-with-border {
        max-width: 100%;
        max-height: 100%;
        align-items: center;
        border: 0.125rem solid colors.$el-gray-300;
        border-radius: 0.5rem;

        &-selected {
            max-width: 100%;
            max-height: 100%;
            align-items: center;
            border: 0.125rem solid colors.$el-purple;
            border-radius: 0.75rem;
        }
    }
}

.collapsible-panel {
    &-closed {
        border: 0.125rem solid #E8E8E8;
        border-radius: 0.4375rem;

        :hover {
            background-color: #F4F4F4;
        }
    }

    &-opened {
        border: none;
    }
}

.collapsible-panel-header-box {
    padding: 1.1875rem 0.5rem 1.0625rem 0.9375rem;
}