@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;

$share-subtext-message-color: #6E6E6E; /* TODO: SPECTRUM_COLOR_CHECK */
$discard-text-message-color: #323232; /* TODO: SPECTRUM_COLOR_CHECK */
$discard-text-heading-color: #2C2C2C; /* TODO: SPECTRUM_COLOR_CHECK */

.share-link-dialog {
    &__container {
        @include dialog.container(40vw, fit-content);
    }

    &__title {
        @include global.generic-font-style(bold, 1.8rem, 2rem);
        margin-bottom: 1.125rem;
        color: $discard-text-heading-color;
    }

    &__again {
        @include global.generic-font-style(normal, 1.125rem, 1rem);
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        color: $share-subtext-message-color; /* TODO: SPECTRUM_COLOR_CHECK */
    }

    &__stop {
        @include global.generic-font-style(normal, 1.125rem, 1rem);
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        color: $share-subtext-message-color; /* TODO: SPECTRUM_COLOR_CHECK */
    }

    &__text {
        @include global.generic-font-style(italic, 1.2rem, 1.5rem);
        color: $discard-text-message-color;  /* TODO: SPECTRUM_COLOR_CHECK */
        margin-top: 2rem;
    }

    &__button-text {
        @include global.generic-font-style(bold, 1.3rem, 1.3rem);
        white-space: nowrap;
    }
}

@media (max-width: screens.$iphone-x-landscape) {
    .share-link-dialog__container {
        @include dialog.container(100%, fit-content);
    }
}