@use "./../../../global.module" as global;
@use "./../../../styles/colors.module" as colors;
@use "./../../../styles/screens.module" as screens;

.feature-cta-box {
  @include global.flex(column);
  @include global.span-parent($height: 80%);
  margin: 2% 12%;
  gap: 4rem;
  min-height: 550px;
}

.feature-banner-box__signup {
  @include global.generic-font-style(900, 1rem, 1rem);
}

.publiclanding-app-icon-box {
  @include global.flex($align: center);
  @include global.span-parent($height: 8rem);
  padding: 0rem 2rem;
}

.publiclanding-app-icon {
  @include global.span-parent($height: 100%);
  background-color: colors.$el-transparent;
  border: 0rem;
  align-self: start;
  pointer-events: none;
}

.publiclanding-app-logo {
  @include global.span-parent(3rem, 3rem);
  display: block;
}

.publiclanding-app-name {
  @include global.generic-font-style(bold, 1.875rem, 3rem);
  color: colors.$el-gray-900;
  padding-left: 0.5rem;
}

@media (max-width: screens.$ipad-pro-portrait) {
  .feature-cta-box {
    @include global.span-parent($height: 98%);
  }
}

@media (max-width: screens.$iphone-x-landscape) {
  .feature-cta-box {
    margin: 2% 4%;
  }
}