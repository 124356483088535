.stage-doc-container {
  position: relative;
  overflow: hidden;
  width: inherit;
  height: 100%;
  background-color: var(--spectrum-global-color-gray-300);
}

.main-canvas {
  position: absolute;

  border: solid 1px var(--spectrum-global-color-gray-400);
  background-color: #ffffff;
  background-image: linear-gradient(45deg, #cccccc 25%, transparent 25%),
    linear-gradient(-45deg, #cccccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #cccccc 75%),
    linear-gradient(-45deg, transparent 75%, #cccccc 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  image-rendering: optimizeSpeed;
  /* Legal fallback */
  image-rendering: -moz-crisp-edges;
  /* Firefox        */
  image-rendering: -o-crisp-edges;
  /* Opera          */
  image-rendering: -webkit-optimize-contrast;
  /* Safari         */
  image-rendering: optimize-contrast;
  /* CSS3 Proposed  */
  image-rendering: crisp-edges;
  /* CSS4 Proposed  */
  image-rendering: pixelated;
  /* CSS4 Proposed  */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE8+           */
}

.cursor-default * {
  cursor: default;
}

.cursor-wait * {
  cursor: progress;
}

.cursor-none * {
  cursor: none;
}

.cursor-pan * {
  cursor: grab;
}

.cursor-zoom-in * {
  cursor: zoom-in;
}

.cursor-zoom-out * {
  cursor: zoom-out;
}