@use "./../../../global.module" as global;
@use "./../../../styles/colors.module" as colors;

.no-page-found {
    @include global.span-parent(35%, 60%);
    background-color: colors.$el-white; 
    margin-top: 10rem;
}

.no-page-found-heading {
    @include global.generic-font-style(1900, 2.35rem, 2.81rem);
    margin-left: 2rem;
    margin-top: 0.5rem;
}

.no-page-found-top-sub-heading {
    @include global.generic-font-style(500, medium, normal);
    margin-left: 2rem;
    margin-top: 1rem;
}

.nopage-found-link{
    @include global.generic-font-style(bold, medium, null){
        text-decoration: underline;
    };
    color: colors.$el-purple-2;  /* todo -- SPECTRUM_COLOR_CHECK */
    align-self: center;
    margin-top: 1rem;
}

.no-page-found-sub-heading {
    @include global.generic-font-style(normal, medium, null, $style: italic);
    margin-left: 2rem;
    margin-top: 1rem;
    align-self: center;
}

.no-page-found-sub-heading-link {
    @include global.span-parent(50%);
    @include global.generic-font-style(normal, medium, null, $style: italic);
    margin-left: 2rem;
    margin-top: 1rem;
    align-self: center;
    margin-right: 2rem;
    word-break: break-word;
}

.no-page-found-img {
    @include global.span-parent(40%, 50%);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    align-self: center;
}

.no-page-found-home-page {
    @include global.generic-font-style(900, 1rem, 1rem);
    margin-top: 1rem;
    align-self: center;
}