@use "./../../../global.module" as global;
@use "./../../../styles/colors.module" as colors;
@use "./../../../styles/zlevel.module" as zIndex;

.navbar-container {
    @include global.flex() {
        @include global.flex-center;
    }

    min-height: global.$navbar-height;
    /* watch out: do update "workspace-container" class if you change this  */
    background: colors.$el-gray-50 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px colors.$el-black-light;
    opacity: 1;
    position: relative;
    /*  this is because navbar should be above media grid (which has loader at z-index:1)
        This impacted utility navbar dropdown
    */
    z-index: zIndex.$level-2;
}