@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;

#el-util-nav-container {
    @include global.span-parent(100%, 100%);

    .utilnav-container {
        @include global.span-parent(100%, 100%);

        .utility-nav-icon {
            padding: 0;
            margin: 0;
                border: none;
            @include global.span-parent(100%, 100%);
            
            .nav-profile-container {
                position: relative;
                @include global.span-parent(100%, 100%);

                .utilnav-profile-wrapper {
                    @include global.span-parent(100%, 100%);

                    .Profile-thumbnail {
                        @include global.span-parent(100%, 100%);
                    }
                }
            }
        }
        /* skipping middle classes */
        .Profile-expanded:before {
            top:3.175rem;
            right: 1.3rem;
        }

        .utilnav-profile-wrapper .Profile-dropdown{
            top:2.8rem;
        }
    }
}

/* FHD screen */
@media (max-width: screens.$fhd-screen) {
    #el-util-nav-container .utilnav-container .utilnav-profile-wrapper .Profile-dropdown{
        top:2.7rem;
    }   
}