@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;
@use "./../../../../styles/colors.module" as colors;

.transparent-icon {
    @include global.span-parent(100%, 100%);
    padding: 0.1825rem;
    box-sizing: border-box;
    cursor: pointer;

    &-selected {
        padding: 0.1825rem;
        border: 0.1825rem solid colors.$el-purple-800;
        border-radius: 0.625rem;
        box-sizing: border-box;
        cursor: pointer;
    }
}

.transparent-text {
    text-align: center;
}