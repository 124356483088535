@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.photo-text-content-opacity-slider {
    padding: 10% 10%;
    justify-content: center;

    ._spectrum-Slider-label_48d11 {
        @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
    }
}