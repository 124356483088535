@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.panel-header-box {
    padding: 0.9rem 0;
    margin: 0 3.3%;
    border-bottom: 0.125em solid colors.$el-gray-400;
    @include global.generic-font-style(900, 1.625rem, 2rem);
    color: colors.$el-black;

    &.start {
        @include global.grid(start);
    }

    &.center {
        @include global.grid(center);
    }
}