@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;

.creation-header {
    height: 4.5rem;
    justify-content: flex-start;
    /* TODO : SPECTRUM_COLOR_CHECK */

    &__edit-box {
        max-width: 66%;
        @include global.span-parent(fit-content);
        display: contents;
    }

    &__title {
        &-text {
            @include global.generic-font-style(700, 1.4rem, 2.8rem);
            padding-left: 0.1em;
            padding-right: 0.1em;
            /* TODO : SPECTRUM_COLOR_CHECK */
            color: #292929;
            white-space: nowrap;
            overflow: clip;
            max-width: 18rem;
            @include global.span-parent(fit-content);
            text-overflow: ellipsis;
        }

        &-tooltip {
            font-size: 1rem;
            font-family: var(--font-family);
            font-style: italic;
            /* TODO : SPECTRUM_COLOR_CHECK */
            color: #292929;
        }
    }

    &__text {
        &-box {
            cursor: default;
            @include global.span-parent(50%);

            ._spectrum-Textfield-input_61339 {
                @include global.generic-font-style(900, 1.6rem, 3rem);
                padding-left: 0.1em;
                padding-right: 0.1em;
                @include global.span-parent(100%, fit-content);
            }
        }

        &-input {
            @include global.span-parent(66%);
            @include global.generic-font-style(900, 1.6rem, 3rem);
            max-width: 18rem;
        }
    }

    &__cta-box {
        @include global.span-parent(50%);
        justify-content: flex-end;
        align-items: center;
    }

    &__created-text {
        @include global.generic-font-style(normal, 1.25rem, 1.75rem, var(--font-family), italic);
        color: colors.$el-gray-800;
    }

    &__editing-title {
        text-overflow: unset;
        @include global.span-parent(58%);
        overflow: hidden;
    }

    &-back-button {
        background-color: #eaeaea;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: #D5D5D5;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &-disabled {
            background-color: #eaeaea;
            /* TODO : SPECTRUM_COLOR_CHECK */
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-button-text {
        @include global.generic-font-style(bold, 1.125rem, 1.875rem);
        white-space: nowrap;
        letter-spacing: 0.72px;
    }

    &-back-dialog {
        &-container {
            @include dialog.container(40vw, fit-content);
        }

        &-heading {
            @include global.generic-font-style(bold, 2.25rem, normal);

            &-description {
                @include global.generic-font-style(normal, 1.5rem, normal);
            }
        }

        &-description-container {
            justify-content: center;

        }

        &-description {
            text-align: center;
            align-self: center;
            @include global.generic-font-style(normal, 1.2rem, normal);
        }

        &-save,
        &-dont-save {
            @include global.generic-font-style(bold, 1.2rem, normal);
            padding: 0rem 0.35rem 0rem 0.35rem;
        }
    }

    &__share-text {
        @include global.generic-font-style(bold, 1.125rem, 1.875rem);
        color: colors.$el-white;
        letter-spacing: 0.72px;

        &--disabled {
            @include global.generic-font-style(bold, 1.125rem, 1.875rem);
            color: #CECECE;
            letter-spacing: 0.72px;
        }
    }

    &__switch-text {
        display: block;
        @include global.generic-font-style(normal, 1.125rem, 1.375rem);

        &-ipad {
            display: none;
        }
    }
}

@media (max-width: screens.$ipad-pro-portrait) {
    .creation-header__share-text {
        display: none;

        &--disabled {
            display: none;
        }
    }

    .creation-header__switch-text {
        display: none;

        &-ipad {
            display: block;
            @include global.generic-font-style(normal, 1.125rem, 2.5rem);
        }
    }
}

._spectrum_d43d2 {
    background-color: transparent;
}

.el-download-option-panel {
    justify-content: space-evenly;
    min-width: 9rem;

    &-heading {
        @include global.generic-font-style(bold, 1rem, 1.5rem);
    }

    &-text {
        @include global.generic-font-style(normal, 1.3rem, 1rem);
    }
}