@use "./../../../../../global.module" as global;
@use "./../../../../../styles/colors.module" as colors;
@use "./../../../../../styles/screens.module" as screens;
@use "./../../../../../styles/dimensions.module" as dimensions;

$el-link-share-blue: #1d24ab;/* TODO -- SPECTRUM_COLOR_CHECK */

#link-share-view {
    &__copy-button {
        font-family: var(--font-family);
        color: colors.$el-gray-50;  
        background-color: $el-link-share-blue; 
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-width: 0px;
        height: var(--spectrum-alias-single-line-height, dimensions.$el-size-400);

        /* iphone 12 Pro Portrait mode */
        @media (max-width: screens.$iphone12-pro-portrait) {
            min-width: max(3.25rem, 64px);
        }
    }

    &__v2-provider {
        background-color: transparent;
    }
}

.link-share-view {
    ._spectrum-FieldLabel_36b9b {
        @include global.generic-font-style(medium, 1.125rem, 8.75rem);

        color: colors.$el-dark-gray;
    }

    ._spectrum-Textfield-input_61339 {
        font-style: italic;
        color: $el-link-share-blue;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right-width: 0px;
    }

    &__other-options {
        @include global.generic-font-style(medium, 1.125rem, 8.75rem);

        color: colors.$el-dark-gray;
    }
}
