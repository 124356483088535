@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.feature-banner-box {
    width: 100%;
    height: auto;
    flex-direction: row;
    color: #000000;
    /*TODO: SPECTRUM_COLOR_CHECK*/
    background-color: white;
    /*TODO: SPECTRUM_COLOR_CHECK*/
    border-radius: 1rem;
}

.feature-banner-img {
    width: 60%;
    height: auto;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    object-fit: cover;
}

.feature-banner-bottom-box {
    width: 40%;
    align-items: center;
    justify-content: center;
    padding: 2% 0;
}

.feature-banner-box__content {
    width: 85%;
    gap: 2rem;
}

.feature-banner-box__cta {
    align-self: flex-start;
    gap: 1rem;
}

.feature-banner-box__heading {
    font: normal normal 900 2rem/2rem var(--font-family);
    letter-spacing: 0;
}

.feature-banner-box__description {
    font: normal normal medium 1.315rem/1.25rem var(--font-family);
}

.feature-banner-box__cta-text {
    font: normal normal bold 1.625rem/2.5rem var(--font-family);
    padding: 0.2rem;

    &-primary {
        @include global.generic-font-style(bold, 1.625rem, 2.5rem);
        padding: 0.2rem;
        white-space: nowrap;
    }
}

.feature-banner-box__signup {
    font: normal normal 900 1rem/1rem var(--font-family);
}

.feature-banner-box__hello {
    text-align: left;
    font: normal normal 1.625rem/1.75rem var(--font-family);
    color: #000000;
    /*TODO: SPECTRUM_COLOR_CHECK*/
    opacity: 1;
}

/*iPad Mini landscape mode */
//PIE_WASM_REVISIT - temp code, to be restored as master
@media (max-width: 1920px) {
    .feature-banner-box {
        flex-direction: column;
        border-radius: 1.5rem;
        height: auto;
    }

    .feature-banner-img {
        width: 100%;
        height: 21rem;
        border-bottom-left-radius: 0;
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
    }

    .feature-banner-bottom-box {
        height: auto;
        width: 100%;
        padding: 2% 0;
    }

    .feature-banner-box__content {
        width: 75%;
        //PIE_WASM_REVISIT - temp code, to be restored as master
        gap: 1rem;
    }

    .feature-banner-box__cta-text {
        font: normal normal 900 1.5rem/1.3rem var(--font-family);
        padding: 0.8rem;
    }

    .feature-banner-box__heading {
        font: normal normal 900 4.5rem/3rem var(--font-family);
    }

    .feature-banner-box__description {
        font: normal normal normal 2.4rem/2.4rem var(--font-family);
    }

    .feature-banner-box__cta {
        //PIE_WASM_REVISIT - temp code, to be restored as master
        gap: 0.1rem;
    }

    .feature-banner-box__signup {
        font: normal normal 900 1.5rem/1.8rem var(--font-family);
    }
}

/* ipad air portrait mode */
@media (max-width: 834px) {
    .feature-banner-box__heading {
        font: normal normal 900 3rem/2.5rem var(--font-family);
    }

    .feature-banner-box__cta {
        gap: 1.2rem;
    }

    .feature-banner-box__signup {
        font: normal normal 900 1.4rem/1.8rem var(--font-family);
    }

    .feature-banner-box__hello {
        font: normal normal 2rem/1.75rem var(--font-family);
    }
}

/* iphone 8 plus landscape mode */
@media (max-width: 736px) {
    .feature-banner-box__description {
        font: normal normal normal 1.5rem/1.8rem var(--font-family);
    }

    .feature-banner-box__content {
        width: 75%;
        gap: 3rem;
        padding: 2rem 0;
    }
}

/* in portrait mode and mobile devices (ipad mini  portrait mode size) */
@media (orientation: portrait) and (min-width: 768px) {

    .feature-banner-box__heading {
        font: normal normal 900 5rem/4.5rem var(--font-family);
    }

    .feature-banner-box__description {
        font: normal normal normal 3rem/3rem var(--font-family);
    }

    .feature-banner-box__cta {
        gap: 1rem;
    }

    .feature-banner-box__hello {
        font: normal normal 3rem/2.5rem var(--font-family);
    }
}