@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.el-feedback {
    &-content {
        &__body {
            margin: 1.5rem 2.5rem;
            text-align: center;
            font: italic normal normal 16px/27px var(--font-family);
        }

        &__heading {
            margin-bottom: 0.9rem;
        }
    }
    
    &-icon {
        @include global.span-parent(1.8rem, 1.8rem);
    }

    &-action-button {
        border: none;
        width: fit-content;
        height: fit-content;
        padding: 1rem;
        border-radius: 10px;
    }

    &-action-button:hover {
        background-color: #EAEAEA;
    }

}

.feedback-cta {
    &__no {
        color: colors.$el-red-700;
        border-color: colors.$el-red-700;
    }

    &__yes {
        color: colors.$el-celery-700;
        border-color: colors.$el-celery-700;
    }
}

.feedback-cta-icon {
    &__no {
        fill: colors.$el-red-700;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.4rem;
    }

    &__yes {
        fill: colors.$el-celery-700;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.4rem;
    }

    &__text {
        text-overflow: clip;
        padding-right: 0.4rem;
    }
}