@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/colors.module" as colors;

.stock-license-download-dialog {
    &__container {
        @include dialog.container(32vw, fit-content);
    }

    &__heading {
        @include global.generic-font-style(bold, 2rem, normal);

        &-description {
            @include global.generic-font-style(normal, 1.15rem, normal);
            color: colors.$el-gray-500;
            font-style: oblique;
        }
    }

    &__description {
        @include global.generic-font-style(normal, 1.2rem, normal);
    }

    &_button-text {
        @include global.generic-font-style(bold, 1.2rem, normal);
        padding: 0rem 0.35rem 0rem 0.35rem;
    }
}