@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

#peekThrough-container {
    @include global.span-parent(100vw, 100%);
    @include global.fade-in(0.3s);
}

#peekThrough-header-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: 0.0625em solid #cecece;
}

#peekThrough-right-panel-container {
    @include global.span-parent(null, 100%);
    flex-shrink: 0;
    height: 100%;
    align-items: flex-end;
    position: relative;
}

#peekThrough-edit-container {
    @include global.span-parent(100%, 100%);
    @include global.flex();
    position: relative;
    overflow: hidden;
    flex-direction: row;
    flex-grow: 1;
}

.peekThrough-main-content-box {
    @include global.flex();
    @include global.creation-content-box;
}

.dialog-box__children.peekThrough-dialog {
    @include global.span-parent(100%, 100%);
    background: colors.$el-off-white;
    opacity: 1;
    flex-grow: 2;
}

.peekThrough-feedback-icon-container {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}