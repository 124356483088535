@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.deletable-media-thumb {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 0.3125rem;
    position: relative;

    &-container {
        position: relative;
    }

    &-button {
        position: absolute;
        right: 3px;
        top: 3px;
        border-radius: 50%;
        border: none;
        display: flex;
        padding: 0;
        @include global.flex-center;
        background-color: colors.$el-white;
        opacity: 0.5;

        &:hover {
            opacity: 1.0;
        }
    }

}
