@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.manage-media-box {
    display: flex;
    gap: 0.4rem;
    @include global.generic-font-style(800, 1.125rem, 1.375rem);
    color: colors.$el-light-gray;
    padding-top: 0.5em;
    padding-left: 1.5em;
    cursor: pointer;
}
