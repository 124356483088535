@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;

$el-feedback-dialog-color: #505050;  /* TODO: SPECTRUM_COLOR_CHECK */

.feedback-dialog{
    &__container {
        @include dialog.container(40vw, fit-content);
    }

    &__title {
        @include global.generic-font-style(bold, 2.25rem, 2.813rem);
        margin-bottom: 1.125rem;
    }

    &__text-area {
        resize: none;
    }

    &__question {
        @include global.generic-font-style(bold, 1.375rem, 1.125rem);
        margin-bottom: 0.625rem;
        color: $el-feedback-dialog-color; /* TODO: SPECTRUM_COLOR_CHECK */
    }

    &__message {
        @include global.generic-font-style(normal, 1.375rem, 1.5rem);
        color: $el-feedback-dialog-color;  /* TODO: SPECTRUM_COLOR_CHECK */
    }
}

/* iphone X landscape mode */
@media (max-width: screens.$iphone-x-landscape) {
    .feedback-dialog__container {
        @include dialog.container(100%, fit-content);
    }
}