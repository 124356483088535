@use "./../../../../styles/colors.module" as colors;

.audio-thumb-div {
    cursor: default;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.3em;
    margin-right: 0.7em;
    border-radius: 0.3em;

    &.selected {
        color: var(--spectrum-global-color-gray-900);
        background-color: colors.$el-gray-300;
    }

    &.non-selected {
        color: var(--spectrum-global-color-gray-900);

        &.playing {
            color: var(--spectrum-global-color-gray-900);
            /* SPECTRUM_COLOR_CHECK */
            background-color: #DEDEF9;
        }
    }

    /* TODO: this was introduced by Aria need to fix this. Outline box was out of proportion due to big svg */
    &:focus-visible {
        outline: none;
    }

    &__title {
        font-size: 1rem;
        padding-left: 0.4em;
        flex-grow: 1;
        justify-self: start;
    }

    &__button {
        padding-left: 0.4em;
    }

    &__duration {
        padding-right: 0.4em;
    }
}

/* 

Need to revisit these classes 

.audio-thumb-div__seek-time {

}

*/