@use "./../../../global.module" as global;
@use "./../../../styles/zlevel.module" as zIndex;
@use "./../../../styles/colors.module" as colors;
@use "./../../../styles/screens.module" as screens;

#user-home-workflow {
    &-container {
        @include global.span-parent(100%, 100%);
    }

    &__banner-container {
        position: relative;
        overflow: hidden;
    }

    &__full_screen-container {
        @include global.span-parent($height: 100%);
        position: fixed;
        top: 0;
        left: 0;
        z-index: zIndex.$level-2;
        background-color: colors.$el-off-white;
    }

    &__cards-container {
        margin: 3% 9.3%;
    }

    &__static-content-container {
        @include global.span-parent($height: auto);
        margin: 3% 0%;
    }
}