$el-gray-900: var(--spectrum-global-color-gray-900);
$el-gray-800: var(--spectrum-global-color-gray-800);
$el-gray-700 :var(--spectrum-global-color-gray-700);
$el-gray-600: var(--spectrum-global-color-gray-600);
$el-gray-500: var(--spectrum-global-color-gray-500);
$el-gray-400: var(--spectrum-global-color-gray-400);
$el-gray-300: var(--spectrum-global-color-gray-300);
$el-gray-200: var(--spectrum-global-color-gray-200);
$el-gray-50: var(--spectrum-global-color-gray-50);
$el-indigo-700: var(--spectrum-global-color-indigo-700);
$el-indigo-600: var(--spectrum-global-color-indigo-600);
$el-indigo-500: var(--spectrum-global-color-indigo-500);
$el-indigo-400: var(--spectrum-global-color-indigo-400);
$el-red-700: var(--spectrum-global-color-red-700);
$el-celery-700: var(--spectrum-global-color-celery-700);
$el-purple-400: var(--spectrum-global-color-purple-400);
$el-seafoam: var(--spectrum-global-color-seafoam-400);
$el-background-transparent: var(--spectrum-alias-background-color-transparent);
$el-purple-800: var(--spectrum-global-color-static-purple-800);

/*TODO: SPECTRUM_COLOR_CHECK*/
$el-white: #FFFFFF;
$el-off-white: #F7F7F7;
$el-light-gray: #2C2C2C;
$el-dark-gray: #2B2B2B;
$navbar-tab-color: #484848;
$app-loading-circle: rgba(247, 247, 247, 42%);
$translucent-black: rgba(0, 0, 0, 0.9);
$translucent-black-low: rgba(0, 0, 0, 0.1);
$el-black: #000;
$el-black-light: #00000005;
$el-purple: #5151D3;
$el-purple-2: rgb(81, 81, 235);
$el-violet: #7B1DC6;
$el-red: #D60000;
$el-transparent: rgba(0, 0, 0, 0);
$translucent-white-high: rgba(255, 255, 255, 0.8);
$el-light-black-font: #292929;