@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.sample-media {
    &__image {
        @include global.span-parent(11.5rem, 7.5rem);
        background: transparent 0% 0% no-repeat padding-box;
        border-radius: 0.3125rem;
        object-fit: cover;

        &.selected, &:hover {
            border: 0.1875rem solid colors.$el-purple-400;
            margin: -0.1875rem; // to maintain the size of the image
        }
    }

    &-item-container {
        @include global.span-parent(100%, 100%);
        display: flex;
        justify-content: center;
    }

    &__select-sample-text {
        text-align: center;
        @include global.generic-font-style(medium, 1.3125rem, 1.625rem);
        color: colors.$el-gray-600;
        margin-top: 2.5rem;
        margin-bottom: 1.25rem;
    }
}