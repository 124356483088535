@use "./../../../../global.module" as global;

@keyframes wave {
    100% {
        transform: translateX(100%);
    }
}

.el-skeleton {
    &--wave {
        position: relative;
        background: #eee;
    }

    &--wave::after {
        position: absolute;
        content: "";
        @include global.span-parent(100%, 100%);
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, #fff, transparent);
        animation: wave 1s infinite;
    }
}