@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;

.quick-action-container {
    @include global.flex(row, center, center);

    border: 0.625rem;
    margin: 0.625rem;
    @include global.span-parent(auto, auto);

    background-color: var(--spectrum-global-color-gray-50);
    padding: 0.625rem;
    border-radius: 0.5rem;  // Optional: round the corners
    box-shadow: 0 0.25rem 0.5rem colors.$el-black-light, 0 0.375rem 1.25rem colors.$el-black-light;  // Elevated shadow effect

    position: absolute;  // Overlay the next-element within the container
    top: 0;
    left: 50%;
    z-index: zIndex.$level-2;  // Ensure it's above the next-element
    pointer-events: auto;

    .quick-action {
        padding: 0.625rem;
        margin-right: 0.15625rem;
        margin-left: 0.15625rem;
    }
}