@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;
@use "./../../../../styles/colors.module" as colors;

.whats-new {
  &-dialog {
    &__content {
      @include global.span-parent(60vw, 65vh);
      @include global.generic-font-style(medium, 1.125rem, 1.25rem);
    }

    &__left-tab-panel {
      flex-direction: column;
      min-width: 18rem;
      background-color: colors.$el-gray-200;
      border-radius: 0.3125rem 0px 0px 0.3125rem;
    }

    &__tabpanels {
      overflow: auto;
    }

    &__cta-text {
      @include global.generic-font-style(bold, 1rem, 1.3125rem);
      letter-spacing: 0.72px;
      color: colors.$el-gray-200;
    }

    &__top-container {
      @include dialog.container(60vw, 65vh);
      border-radius: 0.3125rem;
      overflow: auto;
    }

    &__media {
      padding-bottom: 2%;
      @include global.span-parent(100%, 66%);
      object-fit: cover;
    }
  }

  &__tablist-container {
    @include global.span-parent(null, 100%);
    padding-left: 1.25rem;
    background-color: colors.$el-gray-200;
  }

  &__tablist {
    padding-left: 1rem;

    &-item-and-selection-indicator {
      display: flex;
      position: relative;
      align-items: center;
    }

    &-item__selection-indicator {
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transition: background-color 0.3s;
      background-color: transparent;
      margin-left: -1rem;

      &.selected {
        background-color: colors.$el-gray-900;
      }
    }
  }

  &__tab-name {
    opacity: 1;
    @include global.generic-font-style(normal, 1rem, 1.25rem);
    //TODO: SPECTRUM_COLOR_CHECK
    color: #6E6E6E;

    &.selected {
      @include global.generic-font-style(bold, 1rem, 1.25rem);
      color: colors.$el-gray-900;
    }
  }

  &-tab-body {
    &__description {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      text-align: left;
      @include global.generic-font-style(normal, 1rem, 1.125rem);
      letter-spacing: 0px;
      color: colors.$el-gray-800;
    }

    &__heading {
      padding-left: 1.25rem;
      text-align: left;
      @include global.generic-font-style(900, 1.25rem, 1.25rem);
      letter-spacing: 0px;
      color: colors.$el-gray-900;
    }
  }

  &__top-heading {
    margin-top: 2.5rem;
    margin-left: 2.5rem;
    margin-bottom: 9%;
    @include global.generic-font-style(900, 1.75rem, 2.2rem);
    letter-spacing: 0px;
    color: colors.$el-gray-900;
    opacity: 1;
  }

  &__icon-button-box {
    @include global.span-parent(fit-content, fit-content);
    padding-left: 1.25rem;
  }
}

.whats-new-dialog__top-container {
  ._spectrum-Dialog_30293._spectrum-Dialog--dismissable_30293 ._spectrum-Dialog-grid_30293 {
    grid-template-columns: unset;
    grid-template-rows: unset;
    grid-template-areas: unset;
  }

  ._spectrum-Tabs-selectionIndicator_093dd {
    display: none;
  }

  ._spectrum-Tabs--vertical_093dd ._spectrum-Tabs-item_093dd {
    padding: 0;
    margin: 0.5rem 0 0.5rem 0 !important;
    height: 2rem;
  }

  ._spectrum-Dialog-closeButton_30293 {
    position: absolute;
    background-color: white;
    border-radius: 2rem;
  }
}

@media (max-width: screens.$ipad-pro-portrait) {
  .whats-new {
    &-dialog {
      &__top-container {
        @include dialog.container(80vw, 65vh);
      }

      &__content {
        @include global.span-parent(80vw, 65vh);
      }

      &__cta-text {
        @include global.generic-font-style(bold, 1.5rem, 1.5rem);
        letter-spacing: 0.72px;
        color: colors.$el-gray-200;
      }
    }

    &-tab {
      &-body {
        &__description {
          @include global.generic-font-style(normal, 1.5rem, 2rem);
        }

        &__heading {
          @include global.generic-font-style(bold, 1.5rem, 1.5rem);
        }
      }
    }

    &__tab-name {
      opacity: 1;
      @include global.generic-font-style(normal, 1.25rem, 1.25rem);
      //TODO: SPECTRUM_COLOR_CHECK
      color: #6E6E6E;

      &.selected {
        @include global.generic-font-style(bold, 1.25rem, 1.25rem);
        color: colors.$el-gray-900;
      }
    }
  }
}