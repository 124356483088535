@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.cta-banner-box {
    display: flex;
    align-items: center;
    margin-bottom: 3%;
    background-color: #969696; /*TODO: SPECTRUM_COLOR_CHECK*/
    width: 100%;
    min-height: 10rem;
    border-radius: 1rem;

    &-primary {
        background-color: #EAEAEA; /*TODO: SPECTRUM_COLOR_CHECK*/
        width: 100%;
        min-height: 6rem;
        border-radius: 1rem;
    }

    &__content-container {
        min-height: inherit;
    }
}

.cta-banner-box__description {
    /*SPECTRUM_COLOR_CHECK*/
    color: white;
    font: normal normal normal 2.25rem/1.75rem var(--font-family);

    &-primary {
        color: colors.$el-black;
        @include global.generic-font-style(normal, 1.625rem, 1.75rem);
    }
}

.cta-banner-content-box {
    justify-content: space-around;
    margin: 1rem;

    &-primary {
        justify-content: space-around;
        margin: 1rem;
        align-items: center;
    }
}

/* ipad Air portrait mode */
@media (max-width: 820px) {
    .cta-banner-content-box {
        gap: 2rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 1.5rem;
    }

    .cta-banner-box__description {
        font: normal normal normal 2rem/2.25rem var(--font-family);
    }
}
