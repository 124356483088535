@use "./../../../../global.module" as global;

.video-play-icon {
    position: absolute;
    opacity: 0.5;

    &-dark {
        position: absolute;
        opacity: 0.75;
    }
}

.video-pause-icon {
    position: absolute;
    right: 5px;
    bottom: 28px;
    width: 2rem;
    height: 2rem;
    display: flex;
    @include global.flex-center;
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 50%;
}

.video-box {
    width: 100%;
    display: flex;
    @include global.flex-center;
}
