@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.preview-thumb {
    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-label {
        align-items: center;
        font-family: var(--font-family);
    }

    &-img-container {
        width: 100%;
        cursor: pointer;
        box-sizing: border-box;
        background: transparent;
        padding: 0.1825rem;
        border: 0.1825rem solid transparent;
        border-radius: 0.625rem;
        display: flex;

        &.selected {
            border: 0.1825rem solid colors.$el-indigo-600
        }
    }

    &-imgtag {
        width: 100%;
        border-radius: 0.625rem;
    }

    &-progress-container {
        @include global.span-parent(100%, 10rem);
        box-sizing: border-box;
        background-color: colors.$el-gray-400;
        padding: 0.1825rem;
        border: 0.1825rem solid colors.$el-gray-400;
        border-radius: 0.625rem;
        display: flex;
        margin: 0 6rem;
    }

    &-progress-loader {
        @include global.span-parent(100%, 100%);
        display: flex;
        justify-content: center;
        border-color: colors.$el-indigo-600
    }
}