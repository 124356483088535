@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.creation {
    &-media {
        &-thumb-box {
            @include global.span-parent(7rem, 7rem);
            border-radius: 0.3125rem;
            display: flex;
            @include global.flex-center;
            background: #f4f4f4;
            /* TODO : SPECTRUM_COLOR_CHECK */
        }

        &-thumb {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            border-radius: 0.3125rem;
            position: relative;

            &-container {
                position: relative;
            }
        }

        &-box {
            @include global.span-parent(100%);
        }
    }

    &-panel {
        &-scroll {
            height: 92%;
        }

        &__content-box {
            max-height: 100%;
            padding: 0 3.3%;
        }
    }
}
