@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;


.slideshow {
    &-panel {
        &-box {
            height: 100%;
            background-color: #FFFFFF;
            border-width: max(2px, 0.125rem);
        }

        &__content-box {
            max-height: 100%;
            padding: 0 3.3%;
        }

        &-description {
            @include global.generic-font-style(normal, 1.125rem, 1.375rem);
            padding: 1.4rem 3.3% 1rem;
            color: colors.$el-light-black-font;
        }
    }

    &-right-panel {
        &.opened {
            @include global.span-parent(100%, 100%);
            background-color: rgba(0, 0, 0, 0.25);
        }

        &.closed {
            @include global.span-parent(none, 100%);
        }

        border-radius: 0.3125rem;
        top: 0px;
        right: 0px;
        position: absolute;
    }
}
