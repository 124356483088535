@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;

.el-action-button {
    @include global.span-parent(max(48px, 3rem),max(48px, 3rem));
    border-radius: 50%;
    border: none;
    outline: none;
    display: flex;
    @include global.flex-center;

    &-box {
        transition: top ease 0.3s, left ease 0.3s;
    }

    &-primary {
        /* SPECTRUM_COLOR_CHECK */
        background-color: #2318B2;

        &:hover {
            /* SPECTRUM_COLOR_CHECK */
            background-color: #7B1CC6;
        }
    }

    &-secondary {
        background-color: transparent;
        /* TODO : SPECTRUM_COLOR_CHECK */
        background-color: rgba(0, 0, 0, 0.4);

        &:hover {
            background-color: rgba(41, 41, 41);
            /* TODO : SPECTRUM_COLOR_CHECK */
        }
    }

    &-light {
        background-color: transparent;

        &:hover {
            background-color: rgb(189, 186, 186);
            /* TODO : SPECTRUM_COLOR_CHECK */
        }
    }

    &__action-text {
        padding-top: 0.1em;
        font-size: 1.1rem;
        font-weight: 500;
        font-family: var(--font-family);

        &:hover {
            font-weight: 800;
        }
    }

    &__icon {
        @include global.span-parent(max(1.25rem, 20px),max(1.25rem, 20px));
    }
}

.siv-close-button {
    margin-right: 1.5em;
}

.siv-right-button {
    margin-right: 1em;
}

.siv-left-button {
    margin-left: 1em;
}

/* iphone XR landscape mode */
@media (max-width: screens.$iphone-xr-landscape) {
    .el-action-button__action-text {
        display: none;
    }

    .siv-right-button {
        margin-right: 0em;
    }
    
    .siv-left-button {
        margin-left: 0em;
    }
}
