@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;

$el-feedback-dialog-color: #505050;
/* TODO: SPECTRUM_COLOR_CHECK */

.creation-feedback-dialog {
    &__container {
        @include dialog.container(40vw, fit-content);
    }

    &__heading {
        @include global.generic-font-style(bold, 2.25rem, 2.813rem);
        margin-bottom: 1.125rem;
    }

    &__sub-heading {
        @include global.generic-font-style(normal, 1.2rem, 1rem);
        margin-bottom: 1.125rem;
    }

    &__text-area-heading {
        @include global.generic-font-style(normal, 1.1rem, 1rem);
        margin-bottom: 1.125rem;
        margin-top: 1.125rem;
    }

    &__checkbox-message {
        @include global.generic-font-style(normal, 1rem, 1rem);
        // margin-bottom: 1.125rem;
        // margin-top: 1.125rem;
    }

    &__text-area {
        resize: none;
    }

    &__cta {
        @include global.generic-font-style(bold, 1.2rem, normal);
        padding: 0rem 0.35rem;
    }
}

/* iphone X landscape mode */
@media (max-width: screens.$iphone-x-landscape) {
    .creation-feedback-dialog__container {
        @include dialog.container(100%, fit-content);
    }
}