@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.shared-media-container {
    @include global.span-parent(100%, 100%);
    @include global.flex(column);
    background-color: colors.$el-off-white;
}

.shared-media-container__media-grid {
    @include global.span-parent(100%, 100%);
    position: relative;
    margin: 0 auto;
}

.shared-media-toolbar {
    min-height: 4.5rem;
}


.toolbar__heading {
    @include global.generic-font-style(900, 2.25rem, 2.8125rem);
    color: colors.$el-gray-900;
    opacity: 1;
}

/* Need to make it blue otherwise black */
.toolbar__items-text {
    @include global.generic-font-style(normal, 1.5rem, 1.875rem);
}

.toolbar__items-selected-text {
    /* SPECTRUM_COLOR_CHECK */
    color: colors.$el-purple;
}

.manage-button .el-button-primary {
    @include global.span-parent(8.5625rem, 3rem);
    min-height: 3.5rem;
    border-radius: 3rem;
}

.manage-button__text, .manage-selected-button__text {
    @include global.generic-font-style(bold, 1.625rem, 0rem);
}

.manage-selected-button .el-button-primary {
    @include global.span-parent(17rem, 3rem);
    min-height: 3.5rem;
    border-radius: 3rem;
}

.manage-icon {
    margin-right: 0.5rem;
}

.manage_cta__desktop {
    display: unset;
}

.manage_cta__mobile {
    display: none;
}

.manage-button-text {
    white-space: nowrap;
    @include global.generic-font-style(bold, 1.2rem, 1.8rem);
    letter-spacing: 1.04px;
    padding: 0 0.4rem;
}

@media (max-width: screens.$iphone-xr-landscape) {
    .shared-media-heading {
        @include global.generic-font-style(900, 1.5rem, 1rem);
    }

    .manage_cta__desktop {
        display: none;
    }

    .manage_cta__mobile {
        display: unset;
        position: relative;
        top: 0.5rem;
        color: colors.$el-indigo-700;
        border-color: colors.$el-indigo-700;
    }
}