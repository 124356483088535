@use "./../../global.module" as global;
@use "./../../styles/colors.module" as colors;

#creation-app-container {
    @include global.flex(column) {
        flex: 1 1;
    }
    @include global.span-parent($height: 100%);
    background-color: colors.$el-off-white;
    overflow-y: scroll;
}

#creations-modal-workflow-container {
    @include global.span-parent(100%, 100%);
}
