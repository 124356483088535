@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;

$el-report-abuse-dialog-color: rgb(117, 115, 115);  /* TODO: SPECTRUM_COLOR_CHECK */
$el-report-abuse-dialog-title-color: rgb(70, 69, 69);  /* TODO: SPECTRUM_COLOR_CHECK */
$el-report-abuse-dialog-terms-color: rgb(50, 102, 214);  /* TODO: SPECTRUM_COLOR_CHECK */

@mixin report-text_field($width){
    color: $el-report-abuse-dialog-color;/* TODO : SPECTRUM_COLOR_CHECK */
    @include global.generic-font-style(400, medium, normal);
    width: $width
}

@mixin report-field_label($width){
    @include report-text_field($width);
    padding-right: 1rem;
    text-align: end;
}

.report-abuse {
    &-dialog{
        @include dialog.container(640px, fit-content);
        color: $el-report-abuse-dialog-color; /* TODO : SPECTRUM_COLOR_CHECK */

        &-title {
            @include global.generic-font-style(900, 2.65rem, 2.81rem);
            color: $el-report-abuse-dialog-title-color;
        }

        &-body-text {
            color: $el-report-abuse-dialog-color;/* TODO : SPECTRUM_COLOR_CHECK */
            font-size: medium;
        }

        &-terms-of-use {
            color: $el-report-abuse-dialog-terms-color;/* TODO : SPECTRUM_COLOR_CHECK */
            font-size: medium;
            font-family: var(--font-family);
        }

        &-bold-text {
            text-align: left;
            @include global.generic-font-style(bold, large, normal);
            color: $el-report-abuse-dialog-color;/* TODO : SPECTRUM_COLOR_CHECK */
        }

        &__cta-text {
            @include global.generic-font-style(bold, 1.525rem, 2.5rem);
            padding: 0 0.5rem;
            
        }
    }

    &-radio-button{
        display: inline;
        color: $el-report-abuse-dialog-color;/* TODO : SPECTRUM_COLOR_CHECK */
        font-size: medium;    
    }

    &-cancel-btn{
        margin-right: 2rem;
    }

    &-input-box {
        width: 100%
    }

    &-input-text-field{
        @include report-text_field(60%);
    }

    &__field_label{
        &_name{
            @include report-field_label(30%);
        }

        &_mail{
            @include report-field_label(31%);
        }
    }
}

.nameInputField {
    color: $el-report-abuse-dialog-color; /* TODO : SPECTRUM_COLOR_CHECK */ 
}


.radioGroup{
    flex-flow: row wrap;
    color: $el-report-abuse-dialog-color;/* TODO : SPECTRUM_COLOR_CHECK */
    font-size: medium;
}

.form-group{
    color: $el-report-abuse-dialog-color; /* TODO : SPECTRUM_COLOR_CHECK */
}

#report-abuse-reason-description-message {
    resize: none;
}