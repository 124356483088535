@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.el-number-field {
    @include global.span-parent(5rem, fit-content);
}

@media (max-width: 1370px) {
    .el-number-field {
        @include global.span-parent(6rem, fit-content);
    }
}

@media (max-width: screens.$ipad-pro-portrait) {
    .el-number-field {
        @include global.span-parent(7rem, fit-content);
    }
}