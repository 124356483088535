@use "../../../../styles/colors.module" as colors;

.grid-filter-toolbar__desktop {
    display: flex;
}

.grid-filter-toolbar__mobile {
    display: none;
}

.grid-toolbar__mobile-checkbox-group {
    border-radius: 0.5rem;
    padding-left: 0.8rem;
    background-color: #FFFFFF;
    /* SPECTRUM_COLOR_CHECK */
}

.grid-toolbar-container {
    height: 4.5rem;
    width: 90%;
    /* SPECTRUM_COLOR_CHECK */
    border-bottom: 1px solid #CECECE;
    align-self: center;
    margin: 0 auto;
    position: relative;

    &__close-button {
        position: absolute;
        top: -20%;
        right: -6.5%;
        z-index: 2;
    }
}

.grid-toolbar-container ._spectrum-FieldGroup--positionSide_8cb9b {
    align-items: center;
}

.grid-toolbar-container ._spectrum-Checkbox-label_0ffa4 {
    margin-top: -0.05rem;
}

.grid-toolbar-container__left-items {
    width: 100%;
    height: 50%;
    margin-bottom: 0.5em;
    align-self: flex-end;
}

.all-media-heading {
    text-align: left;
    font: normal normal 900 2rem/2.81rem var(--font-family);
    letter-spacing: 0px;
    /* SPECTRUM_COLOR_CHECK */
    color: #292929;
    opacity: 1;
}

.grid-toolbar-container__left-items .el-button-secondary {
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    min-width: 0 !important;
}

.item-count-heading {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    cursor: pointer;
}

.item-count-heading__text {
    font: normal normal 300 1.5rem/1.875rem var(--font-family);
    letter-spacing: 0px;
    /* SPECTRUM_COLOR_CHECK */
    color: #7B1DC6;
    text-align: left;
    margin-bottom: -0.3rem;
}

.item-count-heading__solid-fill {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* SPECTRUM_COLOR_CHECK */
    background-color: #7B1DC6;
    margin-right: 0.4rem;
    border-radius: 0.2rem;
}

.item-count-heading .deselect-checkmark{
    color: var(--spectrum-global-color-gray-50);
}

.grid-toolbar-container__right-items {
    height: 100%;
    margin: auto;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: flex-end;
    margin-bottom: 1em;
}

.grid-toolbar-container ._spectrum-FieldLabel_36b9b {
    font: normal normal bold 1.2rem/1.5rem var(--font-family);
    letter-spacing: 0px;
    color: #505050;
    opacity: 1;
    padding-top: 0.45rem;
}

.grid-toolbar-container .el-media-grid-toolbar-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
}


.grid-toolbar-container ._spectrum-ActionButton_7a745._is-active_7a745 {
    background-color: #CECECE;
}

.grid-toolbar-container .sort-order-picker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.grid-sort-options-toolbar__desktop {
    display: flex;
}

.grid-sort-options-toolbar__mobile {
    display: none;
}

.grid-toolbar-action-button {
    width: fit-content;
    height: fit-content;
    padding: 0.8rem;
}

.grid-toolbar-sort-dropdown {
    width: 3rem;
    height: 3rem;
}

.grid-toolbar-container .sort-order-picker ._spectrum-Field-wrapper_36b9b {
    padding-top: 0.4rem;
}

.sort-order-picker__item {
    font-size: 16px; /* needed in px or else spectrum commponents breaks */
}

.grid-toolbar__media-type-text {
    font: normal normal normal 1.2rem/1.5rem var(--font-family);
}

.sort-icon-container {
    margin-top: 0em;
    cursor: pointer;
}

.media-import-button-text {
    font: bold 1.3rem/1.8rem var(--font-family);
    letter-spacing: 1.04px;
    padding: 0 0.4rem;
}

.vertical-divider-line {
    display: block;
    width: 0px;
    border: 1px solid #CECECE;
    opacity: 1;
    height: 100%;
}

._spectrum-Dropdown-trigger_06fc4 {
    height: 2.5em;
}

.grid-toolbar-container ._spectrum-Checkbox_0ffa4 {
    margin-top: 0.2em;
}

.hidden-input-container {
    display: none;
}

.sort-icon-container svg {
    pointer-events: none;
}

.media-import-mobile__cta {
    display: none;
}

.media-import-desktop__cta {
    display: block;
}

.grid-toolbar_filter_icon {
    transform: translate(2px, 2px);
    width: 80%;
    height: 2.15rem;
}

/* breakpoint of media filter checkbox group */
@media (max-width: 1140px) {
    .grid-filter-toolbar__desktop {
        display: none;
    }

    .grid-filter-toolbar__mobile {
        display: flex;
    }
}

/* iphone XR landscape mode */
@media (max-width: 896px) {
    .grid-toolbar-container__left-items {
        width: 100%;
    }

    .sort-order-picker {
        display: none !important;
    }

    .media-import-mobile__cta {
        display: flex;
    }

    .media-import-desktop__cta {
        display: none;
    }

    .sort-icon-container {
        margin-top: 0;
    }

    .grid-sort-options-toolbar__desktop {
        display: none;
    }

    .grid-sort-options-toolbar__mobile {
        display: flex;
    }

}

/* iphone 12 Pro Max Portrait mode */
@media (max-width: 428px) {
    .grid-toolbar-filter-box {
        padding-left: 0.2rem;
    }

    .grid-toolbar-container__right-items {
        margin-bottom: .5em;
    }
}