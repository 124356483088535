@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.creation{
    &-img{
        &-thumb {
            max-width: 90%;
            max-height: 90%;
        }

        &-container {
            /* SPECTRUM_COLOR_CHECK */
            background: #EAEAEA 0% 0% no-repeat padding-box;
            @include global.span-parent(16.75rem,16.75rem);
            display: flex;
            @include global.flex-center;
            border-radius: 0.25rem;
            cursor: pointer;
            position: relative;
        }
    }

    &-project{
        &-title-text {
            @include global.generic-font-style(bold,1.5rem,1.875rem);
            color: colors.$el-light-gray;
            max-width: 12rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            /* SPECTRUM_COLOR_CHECK */
        }

        &-type-text {
            @include global.generic-font-style(normal,1.25rem,1.3125rem);
            color: #707070;
            /* SPECTRUM_COLOR_CHECK */
        }
    }
}


.dot {
    font-size: large;
    font-weight: bold;
    letter-spacing: 1.5px;
}
