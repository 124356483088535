@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/dimensions.module" as dimensions;
@use "./../../../../styles/screens.module" as screens;

.action-bar {
    background-color: colors.$el-white;
    border: dimensions.$el-size-10 solid colors.$el-gray-400;
    border-radius: dimensions.$el-size-100;
    box-shadow: 0px 10px 20px colors.$el-gray-500;
    height: 6rem;
    justify-content: center;
    opacity: 0;
    padding: 0.5rem 2rem 0;
    transition: transform ease-in 0.3s;

    &--fade-in {
        @include global.fade-in(1s);
        animation-fill-mode: forwards;
    }

    &--fade-out {
        @include global.fade-out(0.5s);
        animation-fill-mode: forwards;
    }

    &--shift-up {
        transform: translateY(-6.25rem); //6.25rem is the height of import progress bar
    }

    &__button-group {
        gap: 3.4rem;
        padding: 0 1.2rem;
    }
}

@media (max-width: screens.$iphone-xr-landscape) {
    .action-bar {
        @include global.flex(column) {
            @include global.flex-center;
        };

        width: 100vw;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;

        &--shift-up {
            transform: translateY(-8rem); //8rem is the height of import progress bar
        }
    }

    .action-bar__button-group {
        gap: 3.4rem;
    }
}
