@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;


.social-layout {
    &-header {
        margin: 0 3.3% 3%;
        border-bottom: 0.125em solid colors.$el-gray-400;
        @include global.generic-font-style(900, 1.625rem, 2rem);
        justify-content: space-between;
        align-items: center;
        color: colors.$el-black;

        &-box {
            @include global.generic-font-style(bold, 1.125rem, 1.25rem);
            margin: 0.9rem 0;
            width: 100%;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        height: 90%;
        justify-content: space-between;
    }

    &-revert-box {
        margin: 0.9rem 0;
        cursor: pointer;
    }


    &-panel-scroll {
        height: 70%;
        scrollbar-gutter: stable;
    }

    &-scale-slider {
        padding: 5% 5%;
        justify-content: center;

        ._spectrum-Slider-label_48d11 {
            @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
        }
    }

    &-apply-box {
        margin-top: 1rem;
        flex-direction: row;
        justify-content: center;
    }

    &-button-text {
        @include global.generic-font-style(bold, 1.125rem, 1.875rem);
        letter-spacing: 0.72px;
    }
}

@media (max-height: screens.$ipad-mini-portrait) {
    .social-layout-body {
        display: flex;
        flex-direction: column;
        height: 80%;
        justify-content: space-between;
    }
}