@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.audio-box__list-item {
    @include global.span-parent(null,3rem);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

._spectrum-Radio--quiet_db05c ._spectrum-Radio-input_db05c:checked+._spectrum-Radio-button_db05c:before {
    border-color: colors.$el-indigo-700;
}

