@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.looks-panel {
    height: 100%;

    &__header {
        margin: 0 5%;
        border-bottom: 0.125rem solid colors.$el-gray-400;
        @include global.generic-font-style(900, 1.625rem, 2rem);
        justify-content: space-between;
        align-items: center;
        color: colors.$el-light-black-font;
    }

    &__reset {
        margin: 0.9rem 0;
        cursor: pointer;
    }

    &__body {
        flex-direction: column;
        height: 90%;
        justify-content: space-between;
    }

    &__scroll {
        height: 80%;
        scrollbar-gutter: stable;
    }

    &__presets-container {
        max-height: 100%;
        padding: 0 5%;
    }

    &__intensity-slider {
        margin: 0 5%;
        justify-content: center;
        border-top: 0.125rem solid colors.$el-gray-400;
        padding-top: 1.3rem;

        ._spectrum-Slider-label_48d11 {
            @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
        }

        ._spectrum-Slider-fill_48d11,
        ._spectrum-Slider-track_48d11 {
            height: 0.1875rem;
        }
    }
}