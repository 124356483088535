@use "./../../../../global.module" as global;

.edit-error-dialog {
    &__heading {
        @include global.generic-font-style(bold, 1.5rem, 1.875rem);
    }

    &__description {
        @include global.generic-font-style(normal, 1.2rem, 1.875rem);
    }

    &__learn-more {
        @include global.generic-font-style(normal, 1.2rem, 1.875rem);
    }

    &__button-text {
        @include global.generic-font-style(normal, 1.125rem, 1.875rem);
    }
}