@use "./../../../global.module" as global;
@use "./../../../styles/screens.module" as screens;
@use "./../../../styles/dimensions.module" as dimensions;

#share-dialog {
    margin: var(--spectrum-global-dimension-size-0) 3.25rem;
}

.dialog-box__children {
    &.el-share-dialog {
        @include global.span-parent(min(44rem, 96%), min(44rem, 90%));
        
        @media (max-width: screens.$iphone-xr-landscape) {
            @include global.span-parent(100%, 90%);
        }
    }
}

.share-dialog {
    &__content-container {
        @include global.flex(column) {
            @include global.flex-center;
            align-content: center;
            gap: 4rem;
        }

        @media (max-width: screens.$iphone-xr-landscape) {
            gap: 12rem;
        }

        @media (orientation: landscape) and (max-width: screens.$iphone-xr-landscape) {
            gap: 2rem;
        }
    }

    &__msg {
        @include global.generic-font-style(normal, 1.375rem, 1.6875rem, $style: italic);
        width: 28rem;
        text-align: center;
        color: #292929;
        /*TODO - SPECTRUM_COLOR_CHECK*/
    }

    &__description {
        @include global.flex(column, center) {
            align-content: center;
            gap: dimensions.$el-size-300;
        }
    }

    &__cta {
        @include global.flex(column, center) {
            align-content: center;
            gap: 2rem;
        }
    }

    &__dont-show-text {
        @include global.generic-font-style(normal, 1rem, 1.25rem);

        @media (max-width: screens.$iphone-xr-landscape) {
            @include global.generic-font-style(normal, 1.3rem, 1.25rem);
        }
    }
}
