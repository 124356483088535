@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

@mixin buy-desktop-bottom-round {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

@mixin buy-desktop-align-content {
    flex-direction: column;
    align-items: center;
}

.buy-desktop-card {
    width: 40%;
    height: 100%;
    color: #000000;
    /*TODO: SPECTRUM_COLOR_CHECK*/
    background-color: white;
    /*TODO: SPECTRUM_COLOR_CHECK*/
    border-radius: 2rem;
    align-items: end;
    /*TODO: SPECTRUM_COLOR_CHECK*/
    box-shadow: 0 0.25rem 0.25rem rgba(1, 1, 1, .1);

    &__container {
        gap: 2.5rem;
    }

    &__product-name-anchor-style {
        text-decoration: none;
        gap: 2.5rem;

        &:visited {
            color: inherit;
        }

        &:hover {
            text-decoration: underline;
            gap: 2.5rem;
        }
    }

    &__header {
        @include buy-desktop-align-content();
        gap: 1rem;
        width: 90%;
        height: 100%;
        padding-top: 15%;
    }

    &__sub-header {
        @include buy-desktop-align-content();
        gap: 0.5rem;
        min-height: 6.6rem;
    }

    &__content {
        width: 100%;
        gap: 1rem;
        height: 100%;
        @include buy-desktop-align-content();
    }

    &__body {
        width: 100%;
        gap: 1.5rem;
        @include buy-desktop-align-content();
    }

    &__buy-button-text {
        @include global.generic-font-style(bold, 1.625rem, 2.5rem);
        padding: 0.2rem;

        &-primary {
            white-space: nowrap;
        }
    }

    &__buy-button {
        height: max-content;
        border-radius: 3rem;
    }

    &__product-name {
        width: fit-content;
        text-align: center;
        /*TODO: SPECTRUM_COLOR_CHECK*/
        color: #1473e6;
        @include global.generic-font-style(700, 1.8rem, 1.4rem);
        line-height: 2.2rem;
        min-height: 6.5rem;
    }

    &__product-price {
        color: #615a5a;
        @include global.generic-font-style(lighter, 2.2rem, 1rem);
    }

    &__product-description {
        color: var(--spectrum-global-color-gray-900);
        text-align: center;
        @include global.generic-font-style(normal, 1.25rem, 1.2625rem);
        min-height: 2.5rem;
    }

    &__product-price-upgradation {
        color: var(--spectrum-global-color-gray-900);
        text-align: center;
        @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
        text-decoration: underline;
    }

    &__product-price-upgradation-anchor {
        color: var(--spectrum-global-color-gray-900);
    }

    &__bottom-description {
        color: var(--spectrum-global-color-gray-900);
        @include global.generic-font-style(normal, 1.25rem, 1.5625rem);
    }

    &__bottom-box {
        /*TODO: SPECTRUM_COLOR_CHECK*/
        color: white;
        background-color: #40bfff;
        width: 99%;
        justify-content: center;
        padding: 0.5%;
        @include global.generic-font-style(bold, 1.15rem, 1.6625rem);
        @include buy-desktop-bottom-round();
        height: 1.4rem;
    }

    &__heading {
        font: normal normal 900 2rem/2rem var(--font-family);
        @include global.generic-font-style(900, 2rem, 2rem);
        letter-spacing: 0;
    }
}

/*iPad Mini landscape mode */
@media (max-width: 1024px) {

    .buy-desktop-card {
        width: 40%;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__bottom-box {
            width: 100%;
            padding: 2%;
            border-radius: 1rem;
            @include global.generic-font-style(bold, 1.15rem, 1.662rem);
        }

        &__heading {
            @include global.generic-font-style(900, 4.5rem, 3rem);
        }

        &__description {
            @include global.generic-font-style(normal, 2.4rem, 2.4rem);
        }

        &__bottom-box {
            width: 99%;
            justify-content: center;
            padding: 0.5%;
            @include buy-desktop-bottom-round();
        }

    }

}

/* ipad air portrait mode */
@media (max-width: 834px) {

    .buy-desktop-card {
        width: 50%;

        &__container {
            direction: column;
        }
    }
}

/* iphone 8 plus landscape mode */
@media (max-width: 736px) {
    .buy-desktop-card {
        width: 80%;

        &__container {
            gap: 2.5rem;
            direction: column;
        }

    }
}

/* in portrait mode and mobile devices (ipad mini  portrait mode size) */
@media (orientation: portrait) and (min-width: 768px) {

    .buy-desktop-card {
        width: 60%;

        &__container {
            gap: 2.5rem;
            direction: column;
        }

    }
}