@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;

.creation__loading-circle-box {
    @include global.flex() {
        @include global.flex-center;
    }

    flex-direction: row;
    font: normal normal medium 1.125rem/1.875rem var(--font-family);
    position: absolute;
    top: 0;
    left: 0;
    background-color: colors.$translucent-white-high;
    &.parent {
        @include global.span-parent(100%, 100%);
    }
    &.fullscreen {
        width: 100vw;
        height: 100vh;
        z-index: zIndex.$level-3;
    }
}

.creation__loading-circle {
    @include global.flex() {
        @include global.flex-center;
    }

    flex-direction: row;
    width: max-content;
    height: max-content;
    padding: 1.25rem;
    padding-right: 2rem;
    background-color: colors.$el-white;
    gap: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0.1875rem 0.9375rem 1.1875rem #00000042;
    border: 0.0625rem solid colors.$el-white;
}