@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;
@use "./../../../../styles/colors.module" as colors;

.replace-background-content-panel {

    &-box {
        height: 100%;
        background-color: white;
    }

    &-scroll {
        height: 92%;
        scrollbar-gutter: stable;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
    }

    &__header-content {
        padding: 0.9rem 0;
        margin: 0 12%;
    }

    &__header-divider {
        border-bottom: 0.2rem solid colors.$el-gray-400;
        width: 96%;
        margin: 0 2%;
    }

    &__back-button {
        background-color: #eaeaea;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        @include global.flex-center;

        &:hover {
            background-color: #D5D5D5;
            cursor: pointer;
        }
    }

    &-content-box {
        padding: 1rem;
        height: 100%;
    }

    &__custom-section {
        margin-bottom: 1.5rem;
        height: 10%;
    }

    &__custom-section-header {
        font-size: 1 rem;
        font-weight: bolder;
        color: colors.$el-black;
        margin-bottom: 1rem;
    }

    &__section-divider {
        border: none;
        border-top: 1px solid colors.$el-gray-300;
        margin: 1.5rem 0;
    }

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 10rem;
        width: 90%;
    }

    &__adobe-stock-section {
        margin-top: 1.5rem;
    }

    &__adobe-stock-section-header {
        display: flex;
        margin-bottom: 1rem;
    }

    &__adobe-stock-label {
        font-size: 1.2rem;
        font-weight: bold;
        color: colors.$el-black;
    }

    &__adobe-stock-search {
        margin-bottom: 0.75rem;
        margin-top: 0.5rem;
        position: relative;
    }

    &__search-suggestions {
        position: absolute;
        top: 100%; // Position below search bar
        left: 0;
        background-color: white;
        border: 0.0625rem solid colors.$el-gray-400;
        border-radius: 4px;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
        width: 90%;
        z-index: 10;
    }

    &__search-suggestion-item {
        padding: 0.5rem 1rem;
        cursor: pointer;
        color: colors.$el-black;

        &:hover {
            background-color: colors.$el-gray-200;
        }
    }

    &__stock-search-bar-container {
        display: flex;
        align-items: center;
        position: relative;

        input {
            width: 85%;
            height: 1.35rem;
            border: 0.0625rem solid colors.$el-gray-700;
            border-radius: 0.25rem;
            padding: 0.5rem;
            font-size: 1rem;
            outline: none;

            &:focus {
                border-color: colors.$el-black;
                box-shadow: 0 0 0.3125rem colors.$el-black;
            }
        }

        input:not(:placeholder-shown)+.replace-background-content-panel__stock-search-clear-button {
            visibility: visible;
        }
    }

    &__stock-search-clear-button {
        position: absolute;
        right: 9%;
        background: none;
        border: none;
        font-size: 1rem;
        color: colors.$el-gray-700;
        cursor: pointer;
        visibility: hidden;

        &:hover {
            color: colors.$el-black;
        }
    }

    &__featured-free-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        width: 90%;
    }

    &__view-all {
        font-size: 0.875rem;
        color: colors.$el-indigo-600;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__learn-more {
        font-size: 0.875rem;
        color: colors.$el-indigo-600;
        cursor: pointer;
        margin-left: 1%;

        &:hover {
            text-decoration: underline;
        }
    }

    &__featured-free-text {
        font-size: 0.85rem;
        font-weight: bold;
        color: colors.$el-black;
    }

    &__featured-free-grid {
        display: grid;
        grid-template-columns: repeat(2, 8.125rem);
        gap: 0.5rem;
        max-height: 25rem;
        overflow-y: auto;
    }

    &__featured-free-background-item {
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.25rem;
            transition: transform 0.3s, box-shadow 0.3s;

            &:hover {
                transform: scale(1.05);
                box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
            }
        }
    }

    &__powered-by {
        font-size: 0.875rem;
        text-align: center;
        color: colors.$el-gray-700;
        margin-top: 0.5rem;
        margin-right: 6%;
    }

    &__search-results-grid {
        display: grid;
        grid-template-columns: repeat(2, 8.125rem);
        gap: 0.5rem;
        max-height: 34.375rem;
        overflow-y: auto;
        margin-top: 1rem;
    }

    &__search-results-text {
        font-size: 0.75rem;
        color: colors.$el-black;
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    &__search-results-item {
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.25rem;
            transition: transform 0.3s, box-shadow 0.3s;

            &:hover {
                transform: scale(1.05);
                box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
            }
        }
    }

    &__action-region {
        display: flex;
        flex-direction: column;
    }

    &__action-region-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        &.disabled {

            button {
                pointer-events: none;
            }
        }
    }

    &__action-region-text {
        font-size: 0.875rem;
        color: colors.$el-black;
    }

    &__bottom-section-divider {
        border: none;
        border-top: 0.0625rem solid colors.$el-gray-300;
    }

    @media (min-height: 1024px) and (max-height: 1026px) {
        &__adobe-stock-section {
            height: 66%;
        }

        &__featured-free-grid {
            max-height: 38rem;
        }

        &__search-results-grid {
            max-height: 50rem;
        }
    }

    @media (min-height:1027px) and (max-height: 1175px) {
        &__featured-free-grid {
            max-height: 28rem;
        }
    }

    @media (min-height: 1180px) and (max-height: 1359px) {
        &__adobe-stock-section {
            height: 69%;
        }

        &__featured-free-grid {
            max-height: 55rem;
        }

        &__search-results-grid {
            max-height: 74rem;
        }
    }

    @media (min-height: 1366px) {
        &__adobe-stock-section {
            height: 66%;
        }

        &__featured-free-grid {
            max-height: 58rem;
        }

        &__search-results-grid {
            max-height: 80rem;
        }
    }

    @media (min-width: 768px) and (max-width: 1366px) {
        &__action-region-buttons button {
            flex: 1;
            min-width: 6rem;
            height: 2rem;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            box-sizing: border-box;
        }

        &__action-region-buttons {
            display: flex;
            justify-content: space-between;
            gap: 2rem;
        }
    }
}