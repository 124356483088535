@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;
@use "./../../../../styles/colors.module" as colors;

.peekThrough-overlay {
    &-header {
        margin: 0 3.3% 3%;
        border-bottom: 0.125em solid colors.$el-gray-400;
        @include global.generic-font-style(900, 1.625rem, 2rem);
        justify-content: space-between;
        align-items: center;
        color: colors.$el-black;

        &-box {
            @include global.generic-font-style(bold, 1.125rem, 1.25rem);
            margin: 0.9rem 0;
            width: 100%;
        }
    }

    &-panel-box {
        height: 100%;
        background-color: white
    }

    &-revert-box {
        margin: 0.9rem 0;
        cursor: pointer;
    }

    &-scroll {
        height: 92%;
        scrollbar-gutter: stable;
    }

    &-panel-content-box {
        max-height: 100%;
        padding: 10% 5%;
    }

    &-image {
        max-width: 100%;
        max-height: 100%;
        border-radius: 1rem;
        border: 0.125rem solid colors.$el-gray-500;

        &.selected {
            border: 0.1875rem solid colors.$el-purple-400;
            box-shadow: 0.125rem 0.125rem 0.25rem colors.$el-black-light;
        }
    }

    &-card {
        display: flex;
        background: transparent;
        cursor: pointer;
        box-sizing: border-box;

        &.selected {
            border-radius: 0.75rem;
        }
    }

    &-corner-box {
        width: 100%;
        background: colors.$el-gray-300 0% 0% no-repeat padding-box;
        border-radius: 1.25rem;
    }

    &-corner-image {
        border-radius: 0.375rem;
        border: 0.125rem solid colors.$el-gray-500;

        &.selected {
            border: 0.125rem solid colors.$el-indigo-500;
        }
    }

    &-corner-card {
        display: flex;
        padding: 3% 0%;
    }

    &-panel-corner-row-container {
        background: colors.$el-gray-300 0% 0% no-repeat padding-box;
        border-radius: 0.3125rem;
    }

    @media (max-width: screens.$nest-hub-max) {
        &-scroll {
            height: 87%;
            scrollbar-gutter: stable;
        }
    }
}