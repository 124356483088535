@use "./../../styles/colors.module" as colors;
@use "./../../global.module" as global;

.moving-overlay-video {
    @include global.span-parent(100%, 95%);
    color: colors.$el-light-gray;
    border-radius: 0.625rem;
    display: flex;
    justify-self: center;
    align-self: center;
}