@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.template-card {
    &-box {
        border: max(0.0625rem, 3px) solid transparent;
        border-radius: max(0.25rem, 4px);
        padding: 0.2rem;
        flex: 1;

        &.selected {
            border: max(0.0625rem, 3px) solid var(--spectrum-global-color-indigo-600);
        }

        &:hover {
            border: max(0.0625rem, 3px) solid var(--spectrum-global-color-indigo-600);
        }
    }

    &-img {
        width: max(400px, 25rem);
        max-width: 100%;
        max-height: 90%;
        flex: 1;
    }

    &-text {
        @include global.generic-font-style(800, 1.625rem, 2rem);
        color: var(--spectrum-global-color-indigo-600);
        margin-left: 0.3em;
    }

    &-radio-box {
        display: flex;
        @include global.flex-center;
        position: absolute;
        @include global.span-parent(2rem, 2rem);
        top: 5px;
        left: 5px;
        border-radius: 0.2rem;
        background-color: hsla(0, 0%, 96.1%, 0.6);
        /* TODO : SPECTRUM_COLOR_CHECK */
    }

    &-radio {
        margin: 0 !important;
        padding: 0 !important;
    }
}
