@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;

@mixin file-name-text {
    @include global.generic-font-style(900, 1.6rem, 3rem);
    /* TODO : SPECTRUM_COLOR_CHECK */
    color: colors.$el-light-black-font;
}

.edit-workflow-header {
    height: 4.5rem;
    justify-content: flex-start;
    /* TODO : SPECTRUM_COLOR_CHECK */

    &__file-name {   
        @include file-name-text();
        padding-left: 0.1em;
        padding-right: 0.1em;
        white-space: nowrap;
        overflow: hidden;
        max-width: 18rem;
        @include global.span-parent(fit-content);
        text-overflow: ellipsis;
    }

    &__asterisk {
        @include file-name-text();
    }

    &__left-panel {
        cursor: default;
        @include global.span-parent(50%);
    }

    &__cta-box {
        @include global.span-parent(50%);
        justify-content: flex-end;
        align-items: center;
    }

    &__save-text {
        @include global.generic-font-style(bold, 1.125rem, 1.875rem);
        color: colors.$el-white;
        letter-spacing: 0.72px;

        &--disabled {
            @include global.generic-font-style(bold, 1.125rem, 1.875rem);
            color: #CECECE;
            letter-spacing: 0.72px;
        }
    }
}