@use "./../../../../global.module" as global;

.no-media-banner-shared-view{
    &__paras{
        @include global.generic-font-style (normal,1.5rem,2.25rem);
    }

    &__button__text{
        padding: 0.2rem 1.7rem;
        @include global.generic-font-style (normal,1.2rem,1.875rem);
    }
}

.incorrect-collection-shared-view-paras{
    @include global.generic-font-style (normal,1.5rem,2.25rem);
}
