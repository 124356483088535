@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.storage-quota-flyout-content {
    padding: var(--spectrum-global-dimension-size-200, var(--spectrum-alias-size-200));
    width: var(--spectrum-global-dimension-size-3000, var(--spectrum-alias-size-3000));

    &__quota-text {
        display: flex;
        justify-content: space-between;
        color: var(--spectrum-alias-label-text-color, colors.$el-gray-700)
    }
}

.manage-link {
    color: var(--spectrum-alias-label-text-color, colors.$el-gray-700)
}

.cloud-storage {
    display: flex;
    @include global.flex-center;

    &__icon {
        @include global.span-parent(2.5rem, 2rem);
        stroke-width: 2.2px;
        stroke: colors.$el-gray-800;
        cursor: pointer;
    }

    &__warning-icon {
        @include global.span-parent(2.5rem);
        stroke-width: 7px;
        cursor: pointer;

        .el-cloud-storage-warn-st0 {
            stroke: colors.$el-gray-800;
        }
    }

    &__text {
        @include global.generic-font-style(normal, 1.5rem, 3rem);
        color: colors.$el-gray-800;
    }

    &__hovered {
        .cloud-storage {
            &__icon {
                /* For svg */
                stroke-width: 0.3rem;
                stroke: colors.$el-gray-900;

                .cloud-storage-svg-b {
                    fill: colors.$el-gray-900;
                }
            }

            &__warning-icon .el-cloud-storage-warn-st0 {
                /* For svg */
                stroke-width: 0.125rem;
                stroke: colors.$el-gray-900;
                fill: colors.$el-gray-900;
            }

            &__text {
                /* These are for font attrib */
                color: colors.$el-gray-900;
                font-weight: 400;
            }
        }
    }
}


/* MOTO G4 landscape mode */
@media (max-width: screens.$moto-g4-landscape) {
    .cloud-storage__text {
        display: none;
    }
}