@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;
@use "./../../../../styles/zlevel.module" as zIndex;

#siv-parent-container {
    display: flex;
}

#siv-media-container {
    @include global.span-parent(100vw, 100vh);

    @include global.flex {
        @include global.flex-center
    }
}

@mixin siv-container($width) {
    gap: max(16px, 1rem);
    position: relative;
    width: $width;
}

@mixin siv-menubar {
    position: fixed;
    top: 1em;
    justify-content: flex-end;
}

#siv-tag {
    image-orientation: none;
    max-height: 100%;
    max-width: 100%;
}

#siv-container {
    @include siv-container($width: 100%);
}

#siv-container-with-info-panel {
    @include siv-container($width: 74%);
}

#info-panel {
    background: colors.$el-white;
    width: 26%;
}

.dialog-box__children.siv-dialog {
    @include global.span-parent(100%, 100%);
    background: colors.$translucent-black;
    opacity: 1;
    flex-grow: 2;
}

#siv-menubar {
    @include global.span-parent(100%);
    @include siv-menubar();
}

#siv-menubar-with-info-panel {
    @include global.span-parent(74%);
    @include siv-menubar();
}

#asset-name {
    @include global.generic-font-style(400, 1.6rem, 2rem);
    color: colors.$el-white;
    text-align: center;
    opacity: 1;
}

.asset-name-box {
    @include global.flex($justify: center);
    @include global.span-parent(100%);
    position: absolute;
    bottom: 2px;
}

.siv-default-thumb {
    max-width: 50%;
    max-height: 50%;
}

@media (max-width: screens.$iphone-xr-landscape) {
    .siv-default-thumb {
        max-width: 100%;
        max-height: 100%;
    }

    #siv-menubar-with-info-panel {
        @include global.span-parent(100%);
    }

    #info-panel {
        width: 100%;
        height: 70%;
        z-index: zIndex.$level-2;
    }

    #siv-container-with-info-panel {
        width: 100%;
        height: 30%;
    }

    #siv-parent-container {
        flex-direction: column;
        height: 100%;
    }
}