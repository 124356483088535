@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;
@use "./../../../../global.module" as global;


#vertical-tabs-parent {
    flex-direction: column;
    position: relative;
    background: colors.$el-white 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000040;
    opacity: 1;
    border-left: 1px solid #cecece !important;
    border-right: 1px solid #cecece !important;

    ._spectrum-Tabs-selectionIndicator_093dd {
        display: none;
    }
}

.tab-panel-box {
    &.non-modal {
        @include global.span-parent(20rem);
    }

    &.modal {

        &.M {
            @include global.span-parent(65%);
        }

        &.S {
            @include global.span-parent(30%);
        }
    }

    background: colors.$el-white 0% 0% no-repeat padding-box;
    opacity: 1;
}

._spectrum-TabsPanel-tabpanel_093dd {
    border-radius: 0.625em;
}

._spectrum-Tabs--vertical_093dd ._spectrum-Tabs-item_093dd {
    padding: 0;
    margin: 0.5rem 0 0.5rem 0 !important;
    height: 4rem;
}

._spectrum-Tabs_093dd._spectrum-Tabs--vertical_093dd._spectrum-TabsPanel-tabs_093dd {
    padding-right: 0px;
    z-index: zIndex.$level-1;
}

.tab {
    &-list-background {
        position: absolute;
        transform: scale(1.2);
        top: 1.8rem;
        left: -0.5rem;
    }

    &-panel-box {
        border: none;
        z-index: zIndex.$level-1;
    }
}
