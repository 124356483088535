@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.recent-creations-text-box {
    text-align: left;
    @include global.generic-font-style(900,2.25rem,2.81rem);
    color: #292929; /* TODO - SPECTRUM_COLOR_CHECK */
    border-bottom: 1px solid #cecece;
}

.project{
    &-title-text {
        @include global.generic-font-style(bold,1.1rem,1.3rem);
        color: colors.$el-light-gray; /* TODO - SPECTRUM_COLOR_CHECK */
    }

    &-type-text {
        @include global.generic-font-style(normal,0.9rem,1rem);
        color: #707070; /* TODO - SPECTRUM_COLOR_CHECK */
    }
}

.recent-creations-toolbar-right-items {
    justify-content: space-between;
    gap: 8%;
}