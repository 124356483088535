.el-footer-lang-menu {
    &__button {
        background-color: var(--spectrum-gray-50);
        border: none;
        color: var(--spectrum-accent-content-color-default);
        height: fit-content;
        
        &:hover {
            background-color: var(--spectrum-gray-50);
        }
    }

    &__span {
        color: var(--spectrum-global-color-blue-700);

        &:hover {
            text-decoration: underline;
        }
    }
}