@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/screens.module" as screens;

$el-confirmation-dialog-color: #292929;  /* TODO: SPECTRUM_COLOR_CHECK */

.confirmation-dialog{
    @include dialog.container(40vw, fit-content);

    &__h1 {
        @include global.generic-font-style(900, 2.25rem, 2.81rem);
    }

    &__h2 {
        @include global.generic-font-style(normal, 1rem, normal, $style: italic);
        padding: 0.3em;
        /* TODO : SPECTRUM_COLOR_CHECK */
        color: $el-confirmation-dialog-color;
    }

    &__text {
        @include global.generic-font-style(normal, 1.1375rem, 1.6875rem, $style: italic);
        text-align: center;
        padding-bottom: 1em;
        /* TODO : SPECTRUM_COLOR_CHECK */
        color: $el-confirmation-dialog-color;
    }
}

.dialog__cta-text.confirmation-dialog__cta-text {
    padding: 0 3rem;
}

/* iphone X landscape mode */
@media (max-width: screens.$iphone-x-landscape) {
    .confirmation-dialog {
        @include dialog.container(100%, 90vh);
    }
}