@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

#collage-container {
    @include global.span-parent(100vw, 100%);
    @include global.fade-in(0.3s);
}

#collage-header-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: 0.0625em solid #cecece;
}

#collage-right-panel-container {
    @include global.span-parent(null, 100%);
    flex-shrink: 0;
    height: 100%;
    align-items: flex-end;
    position: relative;
}

#collage-edit-container {
    @include global.span-parent(100%, 100%);
    @include global.flex();
    position: relative;
    overflow: hidden;
    flex-direction: row;
    flex-grow: 1;
}

.collage-main-content-box {
    @include global.flex();
    @include global.creation-content-box;
}

.collage__loading-circle-box {
    @include global.flex() {
        @include global.flex-center;
    }

    @include global.span-parent(100%, 100%);
    flex-direction: row;
    font: normal normal medium 1.125rem/1.875rem var(--font-family);
    position: absolute;
    top: 0;
    left: 0;
    background-color: colors.$translucent-white-high;
    z-index: 2; //PIE_WASM_REVISIT - temp code, to be restored as master
}

.collage__loading-circle {
    @include global.flex() {
        @include global.flex-center;
    }

    flex-direction: row;
    width: max-content;
    height: max-content;
    padding: 1.25rem;
    padding-right: 2rem;
    background-color: colors.$el-white;
    gap: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0.1875rem 0.9375rem 1.1875rem #00000042;
    border: 0.0625rem solid colors.$el-white;
}

.dialog-box__children.collage-dialog {
    @include global.span-parent(100%, 100%);
    background: colors.$el-off-white;
    opacity: 1;
    flex-grow: 2;
}

.collage-feedback-icon-container {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}