@use "./../../../../styles/screens.module" as screens;

.single-image-recommendations {
    &-data-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-panel-box {
        height: 100%;
        background-color: white
    }

    &-background {
        &-scroll {
            height: 92%;
            scrollbar-gutter: stable;
        }
    }

    &-panel-content-box {
        max-height: 100%;
        padding: 0 5%;
        margin-top: 5%;
    }

    @media (max-width: screens.$nest-hub-max) {
        &-background-scroll {
            height: 87%;
            scrollbar-gutter: stable;
        }
    }
}