@use "./../../../../styles/colors.module" as colors;
@use "./../../../../global.module" as global;

.el-close-button {
    display: flex;
    @include global.flex-center;
    @include global.span-parent(2rem, 2rem);
    background: colors.$el-white;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid colors.$el-dark-gray;

    &__icon {
        @include global.span-parent(2rem, 2rem);
        margin-top: 1%;
    }
}

@media screen and (max-device-width: 1024px) {
    .el-close-button {
        @include global.span-parent(2.5rem, 2.5rem);
    }
}