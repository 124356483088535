@use "./../../../../global.module" as global;
$animation-banner-content-box: falling 5s linear infinite;

.banner-content-box {
    @include global.span-parent(100%,40%);
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: inherit;

    &__children {
        @include global.grid(center);
    }

    &__artwork-0 {
        position: relative;
        left: 0%;
        animation: $animation-banner-content-box
    }

    &__artwork-1 {
        position: relative;
        left: 6%;
        animation: $animation-banner-content-box;
        animation-delay: 3s;
    }

    &__artwork-2 {
        position: relative;
        left: 13%;
        animation: $animation-banner-content-box;
        animation-delay: 1s;
    }

    &__artwork-3 {
        position: relative;
        left: 21%;
        animation: $animation-banner-content-box;
        animation-delay: 3s;
    }

    &__artwork-4 {
        position: relative;
        left: 30%;
        animation: $animation-banner-content-box;
        animation-delay: 0s;
    }

    &__artwork-5 {
        position: relative;
        left: 37%;
        animation: $animation-banner-content-box;
        animation-delay: 2s;
    }

    &__artwork-6 {
        position: relative;
        left: 45%;
        animation: $animation-banner-content-box;
        animation-delay: 1s;
    }

    &__artwork-7 {
        position: relative;
        left: 55%;
        animation: $animation-banner-content-box;
        animation-delay: 3s;
    }

    &__artwork-8 {
        position: relative;
        left: 61%;
        animation: $animation-banner-content-box;
        animation-delay: 0s;
    }

    &__artwork-9 {
        position: relative;
        left: 67%;
        animation: $animation-banner-content-box;
        animation-delay: 2s;
    }
}

@keyframes falling {
    0% {
        position: relative;
        top: 0%;
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        position: relative;
        top: 88%;
        opacity: 0;
    }
}
