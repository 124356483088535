@use "./../global.module" as global;
@use "./../styles/colors.module" as colors;
@use "./../styles/screens.module" as screens;

.workflow-header__cta-text {
    @include global.generic-font-style(bold, 1.125rem, 1.875rem);
    color: colors.$el-light-gray;
    letter-spacing: 0.72px;
}


@media (max-width: screens.$ipad-pro-portrait) {
    .workflow-header__cta-text {
        display: none;
    }
}