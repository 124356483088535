@use "./../../../global.module" as global;
@use "./../../../styles/colors.module" as colors;
@use "./../../../styles/dialog.module" as dialog;
@use "./../../../styles/screens.module" as screens;

.replace-media {
    &-dialog {
        &-container {
            @include dialog.container(40vw, fit-content);
        }

        &-heading {
            @include global.generic-font-style(bold, 2.25rem, normal);

            &-description {
                @include global.generic-font-style(normal, 1.5rem, normal);
            }
        }

        &-description {
            text-align: center;
            align-self: center;
            @include global.generic-font-style(normal, 1.2rem, normal);
        }

        &-save,
        &-dont-save {
            @include global.generic-font-style(bold, 1.2rem, normal);
            padding: 0rem 0.35rem 0rem 0.35rem;
        }
    }
}